<template>
    <div>
            <primary-btn text="Nuovo Operatore" v-on:click.native="dialog = true" dark />       
            <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Nuovo Operatore</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="operator.name" label="Nome*" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                        Annulla
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="newOperator">
                        Salva
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from '../../../plugins/axios';

export default {
    components: {},
    data() {
        return {
            dialog: false,
            operator: {}
        }

    },
    methods: {
        async newOperator() {
            try {
                const response = await axios.post("/production_operators", this.operator);
                this.$store.dispatch("notificate", {
                    text: 'Operatore aggiunto con successo',
                    type: 'success'
                })
                this.$emit("newOperator");
                this.dialog = false
            } catch (error) {
                console.log(error);
                this.$store.dispatch("notificate", {
                    text: error.response.status === 409 ? 'Operatore gia presente' : 'Operatore non aggiunto, si è verificato un errore',
                    type: error.response.status === 409 ? 'warning' : 'error'
                })
            }
        },

    },
};
</script>