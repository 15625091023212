<template>
    <div>
        <v-btn icon class="!tw-text-white !tw-rounded-xl !tw-capitalize !tw-bg-custom-blue" dark
            @click="dialog = true">
            <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Modifica Operatore</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="operatorData.name" label="Nome*" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                        Annulla
                    </v-btn>
                    <v-btn color="red darken-1" text @click="deleteOperator">
                        Elimina
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="updateOperator">
                        Salva
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from '../../../plugins/axios';

export default {
    components: {},
    props: {
        operator: Object,
        default: () => ({})
    },
    data() {
        return {
            dialog: false,
            operatorData: { ...this.operator }
        }

    },
    methods: {
        async updateOperator() {
            try {
                const response = await axios.patch(`production_operators/${this.operator.id}`, this.operatorData);
                this.dialog = false;
                this.$emit("newOperator");
                this.$store.dispatch("notificate", {
                    text: 'Operatore aggiornato con successo',
                    type: 'success'
                })
            } catch (error) {
                this.$store.dispatch("notificate", {
                    text: error.response.status === 409 ? 'Operatore gia presente' : 'Operatore non aggiornato, si è verificato un errore',
                    type: error.response.status === 409 ? 'warning' : 'error'
                })
            }
        },
        async deleteOperator(operator) {
            this.loading = true
            try {
                const response = await axios.delete("/production_operators/" + this.operator.id);
                this.$emit("newOperator");
                this.$store.dispatch("notificate", {
                    text: 'Operatore eliminato con successo',
                    type: 'success'
                })
                this.dialog = false
            }
            catch (err) {
                this.$store.dispatch("notificate", {
                    text: 'Operatore non eliminato, si è verificato un errore',
                    type: 'error'
                })
            }
        },

    },
};
</script>