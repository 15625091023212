<template>
  <v-container
    class="container-v md:tw-p-32 !tw-p-6 tw-flex tw-flex-col"
    :class="{ dynamically_disable_input: isWorking }"
    fluid
    v-if="project.id"
  >
    <form class="tw-flex tw-flex-wrap tw-mb-12 generic-data">
      <div class="tw-w-full">
        <div
          class="tw-mb-8 tw-flex md:tw-flex-row md:tw-items-center tw-flex-col"
        >
          <div class="tw-flex tw-items-center">
            <v-icon
              @click="goBack"
              class="!tw-text-dark tw-mr-4 hover:tw-opacity-80 tw-cursor-pointer"
              >mdi-arrow-expand-left</v-icon
            >
            <h2 class="tw-text-3xl tw-font-bold">
              Progetto N° {{ project.id }}
            </h2>
          </div>

          <div
            class="tw-flex md:tw-overflow-x-hidden tw-overflow-x-scroll tw-mt-4 md:tw-mt-0 md:tw-ml-4"
          >
            <div class="tw-ml-4">
              <work-counter
                @stop-working="handleStopping"
                :role="user.role"
                v-if="project.status == 'in lavorazione'"
                :time="start_time_tracciato"
              ></work-counter>
            </div>
            <div>
              <v-chip
                v-if="
                  project.last_status == 'in attesa' &&
                  project.status != 'in lavorazione'
                "
                dark
                color="orange"
                class="tw-ml-4"
                >In attesa</v-chip
              >
              <v-chip
                v-else-if="
                  project.last_status == 'completato' &&
                  project.status != 'in lavorazione'
                "
                dark
                color="green"
                class="tw-ml-4"
                >Completato</v-chip
              >
            </div>
            <div>
              <v-chip
                dark
                color="blue"
                class="is_editing tw-ml-4"
                v-if="project.is_editing && project.is_editing != user.username"
                >Aperto da {{ project.is_editing }}</v-chip
              >
            </div>
            <div>
              <v-chip
                @click="openProductionDialog"
                v-if="project.production_status == 'file da caricare'"
                dark
                color="lightgray"
                class="tw-ml-4"
              >
                Produzione in attesa di file
              </v-chip>
              <v-chip
                @click="openProductionDialog"
                v-else-if="project.production_status == 'in attesa'"
                dark
                color="orange"
                class="tw-ml-4"
              >
                Produzione in attesa
              </v-chip>
              <v-chip
                @click="openProductionDialog"
                v-else-if="project.production_status == 'da completare'"
                dark
                color="lightgreen"
                class="tw-ml-4"
              >
                Produzione da completare
              </v-chip>
              <v-chip
                v-else-if="project.production_status == 'completato'"
                dark
                color="green"
                class="tw-ml-4"
              >
                Produzioni completate
              </v-chip>
            </div>
            <div class="tw-ml-4">
              <v-chip
                v-if="isLocked && user.role == 'master'"
                dark
                color="blue"
              >
                <v-icon dark>mdi-lock</v-icon>
                <span class="tw-ml-2">Modalità sola lettura</span>
              </v-chip>
            </div>
            <div>
              <missingInformationButton
                class="tw-ml-4"
                v-on:missing_info_talk="setTalk"
                ref="missing_info"
                v-if="project.id"
                :pid="project.id"
                :text="project.missing_informations.text"
                :role="user.role"
                :delegate="project.delegate"
              ></missingInformationButton>
            </div>
          </div>
          <div
            class="tw-flex tw-flex-1 md-tw-justify-end tw-justify-start tw-mr-4 tw-mb-2 tw-opacity-60"
          >
            <div class="tw-underline font2 tw-mt-2" v-if="edited">
              Ci sono dati non salvati
            </div>
          </div>
          <div class="tw-flex md:tw-justify-end tw-items-center">
            <primary-btn
              v-if="edited"
              :loading="isLoading.saving"
              @click.native="updateProject"
              id="action-btn"
              class="tw-self-baseline tw-mr-2"
              text="Salva Progetto"
            ></primary-btn>
            <primary-btn
              v-else
              :disabled="true"
              @click.native="updateProject"
              id="action-btn"
              class="tw-self-baseline tw-mr-2 tw-cursor-not-allowed"
              text="Progetto salvato"
            ></primary-btn>
            <v-menu
              v-model="actions_dialog"
              offset-y
              content-class="!tw-shadow-xl !tw-rounded-lg !tw-w-auto !tw-min-w-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <secondary-btn
                  :loading="isLoading.actions"
                  :class="{ 'tw-animate-pulse': edited }"
                  v-bind="attrs"
                  v-on="on"
                  @click.native="actions_dialog = true"
                  id="action-btn"
                  class="tw-self-baseline"
                  text="Altre Azioni"
                  icon="mdi-menu-down"
                ></secondary-btn>
              </template>
              <v-list class="font2">
                <action-element
                  v-if="user.role == 'master' && !isLocked && project.status != 'completato' && !project.missing_informations.are_missing"
                  color="green"
                  @click.native="completeProject"
                  text="Completa"
                  icon="mdi-check-bold"
                ></action-element>
                <action-element
                  v-if="!isDisabled && user.role == 'commerciale'"
                  color="blue"
                  @click.native="openProductionDialog"
                  text="Nuova Produzione"
                  class="!tw-text-blue"
                  icon="mdi-robot-industrial"
                ></action-element>
                <action-element
                  v-else-if="
                    project.productions &&
                    project.production_status != 'completato' &&
                    user.role == 'master'
                  "
                  color="blue"
                  @click.native="openProductionDialog"
                  text="Modifica Produzione"
                  class="!tw-text-blue"
                  icon="mdi-robot-industrial"
                ></action-element>
                <action-element
                  color="purple"
                  @click.native="cloneProject"
                  text="Clona Progetto"
                  icon="mdi-content-copy"
                ></action-element>
                <action-element
                  v-if="user.role == 'commerciale'"
                  color="orange"
                  @click.native="newDispute"
                  text="Contestazione"
                  icon="mdi-alert-plus"
                ></action-element>
                <action-element
                  @click.native="goBack"
                  text="Chiudi Progetto"
                  icon="mdi-close-thick"
                ></action-element>
                <action-element
                  v-if="user.role == 'master' && project.last_status != 'completato' && project.status != 'completato' && !project.missing_informations.are_missing"
                  color="black"
                  @click.native="missingInfo"
                  text="Info Mancanti"
                  icon="mdi-information"
                ></action-element>
                <action-element
                  color="red"
                  @click.native="deleteProject"
                  text="Elimina Progetto"
                  icon="mdi-delete"
                ></action-element>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-flex-col tw-w-full md:tw-flex-row tw-flex-wrap">
        <v-col class="input-data tw-p-0 tw-pr-4 !tw-text-sm">
          <div
            class="generic-info md:tw-flex-row tw-flex-col tw-flex md:tw-items-center"
          >
            <label
              class="tw-mb-2 md:tw-mb-0 tw-font-semibold !tw-text-sm md:!tw-text-lg"
              >Cliente*</label
            >
            <div
              class="!tw-relative tw-flex !tw-min-h-[3rem] md:tw-ml-8 tw-mr-4 tw-w-full tw-z-50 tw-items-center"
            >
              <custom-select
                @input="checkEdit"
                :clearable="false"
                :options="customers_list"
                v-model="project.customer"
                :disabled="isDisabled"
                required
                label="name"
                class="select-dis !tw-bg-white tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser tw-mr-2"
              >
              </custom-select>
              <CustomerModal @emitNewCustomer="updateCustomers" />
            </div>
          </div>
          <div
            class="generic-info md:tw-flex-row tw-flex-col tw-flex md:tw-items-center"
          >
            <label
              class="tw-mb-2 md:tw-mb-0 tw-font-semibold !tw-text-sm md:!tw-text-lg"
              >Progetto*</label
            >
            <input-field
              class="md:tw-ml-8 tw-w-full"
              type="text"
              v-model="project.name"
              placeholder="Inserisci il nome del progetto"
              :disabled="isDisabled"
              required
              @input.native="checkEdit"
            ></input-field>
          </div>
        </v-col>
        <v-col class="input-data tw-p-0">
          <div
            class="generic-info md:tw-flex-row tw-flex-col tw-flex md:tw-items-center"
          >
            <label
              class="tw-mb-2 md:tw-mb-0 tw-font-semibold !tw-text-sm md:!tw-text-lg"
              >Corrispondente*</label
            >
            <input-field
              class="md:tw-ml-8 tw-w-full"
              type="text"
              v-model="project.referrer"
              placeholder="Inserisci il nome del referente"
              required
              @input.native="checkEdit"
              disabled
            ></input-field>
          </div>
          <div
            class="generic-info md:tw-flex-row tw-flex-col tw-flex md:tw-items-center"
          >
            <label
              class="tw-mb-2 md:tw-mb-0 tw-font-semibold !tw-text-sm md:!tw-text-lg"
              >CC</label
            >
            <div
              class="!tw-relative tw-flex !tw-min-h-[3rem] md:tw-ml-8 tw-w-full"
            >
              <custom-select
                placeholder="Seleziona CC"
                @input="checkEdit"
                :clearable="true"
                :options="secondary_referrers"
                required
                class="!tw-bg-white tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                v-model="project.secondary_referrer"
              >
              </custom-select>
            </div>
          </div>
        </v-col>
        <v-col class="input-data tw-p-0 md:tw-pl-4">
          <div
            class="generic-info md:tw-flex-row tw-flex-col tw-flex md:tw-items-center"
          >
            <label
              class="tw-mb-2 md:tw-mb-0 tw-font-semibold !tw-text-sm md:!tw-text-lg"
              >Operatore</label
            >
            <div
              class="!tw-relative tw-flex !tw-min-h-[3rem] md:tw-ml-8 tw-w-full"
            >
              <custom-select
                :clearable="false"
                :options="delegates"
                :disabled="isDisabled"
                required
                class="!tw-bg-white tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                v-model="project.delegate"
                @input="delegateProject"
              >
              </custom-select>
            </div>
          </div>
          <div
            class="generic-info md:tw-flex-row tw-flex-col tw-flex md:tw-items-center"
          >
            <label
              class="tw-mb-2 md:tw-mb-0 tw-font-semibold !tw-text-sm md:!tw-text-lg"
              >Priorità</label
            >
            <div
              class="!tw-relative tw-flex !tw-min-h-[3rem] md:tw-ml-8 tw-w-full"
            >
              <custom-select
                :clearable="false"
                :options="priorities"
                :disabled="true"
                required
                class="!tw-bg-white tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                v-model="priorityStatus"
              >
              </custom-select>
            </div>
          </div>
          <div></div>
        </v-col>
      </div>
    </form>
    <v-row class="tw-h-3/4 tw-flex-1 tw-box-border tw-rounded-lg tw-p-0">
      <v-col class="data-info tw-flex md:tw-flex-col tw-flex-col tw-p-0">
        <v-tabs
          show-arrows
          v-model="tabs"
          fixed-tabs
          background-color="#2F2F2F"
          height="100%"
          dark
          class="tw-rounded-t-xl !tw-text-white !tw-h-24"
          active-class="active-tab"
        >
          <v-tabs-slider color="#11797b"></v-tabs-slider>
          <v-tab
            href="#mobile-tabs-5-1"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg !tw-ml-4 tw-text-white"
          >
            Progettazione 
          </v-tab>

          <v-tab
            href="#mobile-tabs-5-2"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Allegati ICA
            <CounterBadge :num="schede_tecniche.length + mastrini.length" />
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-3"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Altri allegati 
            <CounterBadge :num="customer_files.length + three_ds.length" />
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-11"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Impianti
            <CounterBadge
              :num="
                project.quotations.filter((x) => x.type == 'preventivi_stampa')
                  .length
              "
            />
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-4"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Impianti (vecchio sistema)
            <CounterBadge
              :num="
                project.preventivi_fustella.length +
                project.preventivi_stampa.length
              "
            />
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-12"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Archiviati ICA
            <CounterBadge
              :num="archivedSchede.length + archivedMastrini.length"
            />
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-13"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Archiviati (altro)
            <CounterBadge
              :num="archivedCustomer.length + archivedThreeD.length"
            />
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-6"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Preventivi
            <CounterBadge :num="project.preventivi_scatola.length" />
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-7"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Tempi Progettazione
            <CounterBadge :num="project.tracciato.length" />
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-8"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Produzione
            <div v-if="project.productions && project.productions.produzione.slice(0, 1)">
              <CounterBadge :num="project.productions.produzione.length" />
            </div>
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-5"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Pagamenti
            <CounterBadge :num="project.payments.length" />
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-9"
            class="hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg !tw-mr-4 tw-text-white"
          >
            ECT/BCT
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-10"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Realtà Aumentata
            <CounterBadge :num="project.ar_projects.length" />
          </v-tab>
        </v-tabs>
        <v-tabs-items
          class="md:!tw-h-full !tw-h-[fit-content] md:tw-pb-0 tw-pb-32"
          v-model="tabs"
        >
          <v-tab-item :value="'mobile-tabs-5-1'">
            <v-card
              flat
              class="tw-min-h-full !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border md:tw-p-6 tw-p-4"
            >
              <v-row class="time-content">
                <v-col cols="12" class="tw-p-0 tw-mb-8 tw-flex tw-items-center">
                  <div class="tw-font-semibold tw-text-2xl">
                    Progettazione Tecnica
                  </div>
                </v-col>
                <form
                  class="tw-w-full tw-flex md:tw-flex-wrap md:tw-flex-row tw-flex-col generic-data font2"
                >
                  <div
                    class="tw-flex md:tw-flex-row tw-flex-col md:tw-items-center"
                  >
                    <label class="tw-flex-1 md:tw-mb-0 tw-mb-2"
                      >Tipo Imballo</label
                    >
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:!tw-w-96 md:tw-flex-none tw-flex-1"
                      type="text"
                      v-model="project.progettazione_tecnica.imballo"
                      placeholder="Inserisci il tipo di imballo"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                  </div>
                  <div
                    class="tw-flex md:tw-flex-row tw-flex-col md:tw-items-center md:tw-ml-12 md:tw-mt-0 tw-mt-4"
                  >
                    <label class="md:tw-mb-0 tw-mb-2">FEFCO</label>
                    <input-field
                      darker="true"
                      class="md:!tw-w-96 md:tw-flex-none tw-flex-1"
                      type="text"
                      v-model="project.progettazione_tecnica.fefco"
                      placeholder="FEFCO"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                  </div>
                  <div
                    class="tw-flex md:tw-flex-row tw-flex-col md:tw-items-center md:tw-ml-12 md:tw-mt-0 tw-mt-4"
                  >
                    <label class="md:tw-mb-0 tw-mb-2">Onda</label>
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:!tw-w-24 md:tw-flex-none tw-flex-1"
                      type="text"
                      v-model="project.progettazione_tecnica.comp"
                      placeholder="Onda"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                  </div>
                  <div
                    v-if="!est_or_int"
                    class="tw-flex md:tw-items-center md:tw-flex-wrap md:tw-flex-row tw-flex-col md:tw-w-1/2 tw-w-full md:tw-mt-12 tw-mt-4"
                  >
                    <label class="md:tw-mb-0 tw-mb-2">Dimensioni</label>
                    <div
                      class="!tw-relative tw-flex !tw-min-h-[3rem] md:tw-ml-8 md:tw-mr-4 md:!tw-w-80 md:tw-flex-none tw-flex-1"
                    >
                      <custom-select
                        placeholder="Seleziona le dimensioni"
                        :clearable="false"
                        :options="['Esterne', 'Interne']"
                        :disabled="isDisabled"
                        class="!tw-bg-grey tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                        v-model="est_or_int"
                      >
                      </custom-select>
                    </div>
                  </div>
                  <span
                    v-if="est_or_int == 'Esterne'"
                    class="tw-flex md:tw-items-center tw-flex-wrap md:tw-w-1/2 tw-w-full tw-mt-4"
                  >
                    <label class="md:tw-w-auto tw-w-full md:tw-mb-0 tw-mb-2"
                      >Dim. Esterne
                    </label>
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:!tw-w-24 tw-w-1/3"
                      type="number"
                      v-model="project.progettazione_tecnica.dim_est.l"
                      placeholder="L"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                    <input-field
                      darker="true"
                      class="md:!tw-w-24 tw-w-1/3"
                      type="number"
                      v-model="project.progettazione_tecnica.dim_est.p"
                      placeholder="W"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:!tw-w-24 tw-w-1/3"
                      type="number"
                      v-model="project.progettazione_tecnica.dim_est.h"
                      placeholder="H"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                    <span class="tw-w-full md:tw-w-auto">
                      <v-icon
                        v-if="are_dimensions"
                        @click="est_or_int = null"
                        style="cursor: pointer; margin-left: 15px"
                        >mdi-delete</v-icon
                      >
                    </span>
                  </span>
                  <span
                    v-else-if="est_or_int == 'Interne'"
                    class="tw-flex md:tw-items-center tw-flex-wrap md:tw-w-1/2 tw-w-full tw-mt-4"
                  >
                    <label class="md:tw-w-auto tw-w-full md:tw-mb-0 tw-mb-2"
                      >Dim. Interne</label
                    >
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:!tw-w-24 tw-w-1/3 tw-px-1"
                      type="number"
                      v-model="project.progettazione_tecnica.dim_int.l"
                      placeholder="L"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:!tw-w-24 tw-w-1/3 tw-px-1"
                      type="number"
                      v-model="project.progettazione_tecnica.dim_int.p"
                      placeholder="W"
                      :disabled="isDisabled"
                      required
                      @input.native="checkEdit"
                    ></input-field>
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:!tw-w-24 tw-w-1/3 tw-px-1"
                      type="number"
                      v-model="project.progettazione_tecnica.dim_int.h"
                      placeholder="H"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                    <span>
                      <v-icon
                        v-if="are_dimensions"
                        @click="est_or_int = null"
                        style="cursor: pointer; margin-left: 15px"
                        >mdi-delete</v-icon
                      >
                    </span>
                  </span>
                  <div
                    class="tw-flex md:tw-flex-row tw-flex-col md:tw-items-center md:tw-ml-1 md:tw-mt- tw-mt-4"
                  >
                    <label class="md:tw-w-auto tw-w-full md:tw-mb-0 tw-mb-2"
                      >Tipologia di stampa</label
                    >
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:tw-mr-6 md:!tw-w-96 tw-w-full"
                      type="text"
                      v-model="project.progettazione_grafica.stampa"
                      placeholder="Inserisci il tipo di stampa"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                  </div>
                  <div
                    class="tw-flex md:tw-flex-row tw-flex-col md:tw-items-center tw-mt-4"
                  >
                    <label class="md:tw-w-auto tw-w-full md:tw-mb-0 tw-mb-2"
                      >Colori</label
                    >
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 !tw-w-24"
                      type="number"
                      v-model="project.progettazione_grafica.colori"
                      placeholder="0"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                  </div>
                </form>
                <div class="tw-w-full tw-mt-12 tw-h-96">
                  <NotesComponent :_id="project._id" />
                </div>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-2'">
            <v-card
              flat
              class="tw-h-full md:tw-overflow-y-hidden tw-overflow-y-auto !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border md:tw-p-6 tw-p-4"
            >
              <v-dialog
                :retain-focus="false"
                v-model="fileDialog"
                width="600"
                background-color="white"
              >
                <v-row class="wh">
                  <v-col cols="12">
                    <v-card elevation="0">
                      <div
                        v-if="form_file == 'ica-file'"
                        class="tw-font-semibold tw-text-2xl tw-my-4"
                      >
                        Allegati ICA
                      </div>
                      <div v-else class="tw-text-xl tw-font-bold">
                        Allegati Cliente
                      </div>
                      <div class="tw-flex tw-flex-col">
                        <div
                          v-for="(file, index) in current_uploading"
                          :key="index"
                          class="tw-w-full tw-flex tw-flex-col tw-bg-grey tw-mb-2 tw-rounded-xl tw-p-4"
                        >
                          <div class="tw-text-md tw-font-medium">
                            {{ file.name }}
                          </div>
                          <div class="tw-text-sm">{{ file.size }}MB</div>
                          <v-progress-linear
                            v-if="uploading != 100"
                            color="#11797B"
                            class="tw-mt-2"
                            rounded
                            :value="uploading"
                          ></v-progress-linear>
                          <v-progress-linear
                            v-else
                            color="green"
                            class="tw-mt-2"
                            rounded
                            :value="uploading"
                          ></v-progress-linear>
                        </div>
                      </div>
                      <v-col
                        sm="12"
                        class="input-ica tw-h-72 tw-mt-8 !tw-rounded-xl"
                      >
                        <div class="upload-icon">
                          <v-icon color="primary" dark>mdi-upload </v-icon>
                          <p style="font-size: 13px">
                            Clicca o trascina i file qui per caricarli
                          </p>
                        </div>
                        <input
                          v-if="form_file == 'ica-file'"
                          @input="uploadFiles"
                          ref="ica_files"
                          id="upload-false_ica-file"
                          :name="form_file"
                          type="file"
                          size="1"
                          multiple
                        />
                        <input
                          v-else
                          @input="uploadFiles"
                          ref="customer_files"
                          id="upload-false_customer-file"
                          :name="form_file"
                          type="file"
                          size="1"
                          multiple
                        />
                      </v-col>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="closeFileDialog"
                          :loading="isLoading.files"
                        >
                          Chiudi
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-dialog>
              <div class="tw-w-full tw-flex tw-flex-col tw-h-full">
                <div class="tw-w-full tw-flex tw-flex-col">
                  <div class="tw-mb-8 tw-flex tw-items-center">
                    <div class="tw-font-semibold tw-text-2xl">
                      Allegati ICA ({{ project.ica_files.length }})
                    </div>
                    <v-menu
                      v-model="multiple_ica_files"
                      offset-y
                      content-class="!tw-shadow-xl !tw-rounded-lg tw-w-auto tw-w-min-0 "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <secondary-btn
                          :loading="isLoading.actions"
                          v-bind="attrs"
                          v-on="on"
                          @click.native="multiple_ica_files = true"
                          id="action-btn"
                          class="tw-self-baseline tw-ml-2"
                          text="Azioni multiple"
                          icon="mdi-menu-down"
                        ></secondary-btn>
                      </template>
                      <v-list class="font2">
                        <!-- <action-element
                          color="gray"
                          @click.native="bulkFilesAction('archive', 'ica')"
                          :text="'Archivia'"
                          icon="mdi-archive"
                        ></action-element> -->
                        <action-element
                          v-if="project.id_esterno || project.id_esterno == 0"
                          color="green"
                          @click.native="bulkFilesAction('transfer', 'ica')"
                          :text="'Trasferisci a ' + project.esterno"
                          icon="mdi-send"
                        ></action-element>
                        <action-element
                          color="green"
                          @click.native="bulkFilesAction('download', 'ica')"
                          text="Scarica"
                          icon="mdi-download"
                        ></action-element>
                        <action-element
                          color="red"
                          @click.native="bulkFilesAction('delete', 'ica')"
                          text="Cancella"
                          icon="mdi-delete"
                        ></action-element>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
                <div
                  class="tw-w-full tw-flex md:tw-flex-row tw-flex-col tw-h-full"
                >
                  <div class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col">
                    <div class="tw-mb-8 tw-flex tw-items-center">
                      <div class="tw-text-xl">Schede tecniche</div>
                      <primary-btn
                        :disabled="isLocked"
                        @click.native="
                          openUploadFileDialog('ica-file', 'scheda_tecnica')
                        "
                        class="tw-ml-4 tw-mr-2"
                        text="Carica file"
                        icon="mdi-upload"
                      ></primary-btn>
                    </div>
                    <div class="file-list-wrapper tw-flex-1 tw-h-full">
                      <div
                        class="file-list tw-w-full tw-min-h-1/2 tw-max-h-[87%] tw-overflow-y-scroll md:tw-pr-4 tw-box-border"
                      >
                        <div
                          v-for="(fname, index) in schede_tecniche"
                          :key="index"
                          class="file-obj tw-full tw-flex tw-bg-grey tw-box-border tw-p-4 tw-rounded-xl tw-items-center tw-mb-4 tw-relative"
                        >
                          <div class="tw-mr-2 tw-flex tw-items-center">
                            <v-checkbox
                              v-model="selected_ica_files"
                              :value="fname"
                            ></v-checkbox>
                          </div>
                          <div class="">
                            <img
                              class="tw-16 tw-h-16"
                              :src="
                                path +
                                '/static/img/file_ico/' +
                                getFileIco(fname.name) +
                                '.png'
                              "
                              alt=""
                            />
                          </div>
                          <div class="tw-flex-1 tw-flex-col font2">
                            <p class="tw-font-bold tw-text-sm md:tw-text-md">
                              {{ fname.name.replace(/_/g, " ") }}
                            </p>
                            <p class="tw-text-xs">
                              {{ (fname.size / (1024 * 1024)).toFixed(2) }} MB
                            </p>
                          </div>
                          <div class="tw-flex tw-bg-red">
                            <!-- <v-icon v-if="user.role == 'commerciale' && project.id_esterno" @click="transferData('ica_file',fname)" class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue">mdi-send</v-icon> -->

                            <v-icon
                              @click="shareFiles(fname.name, 'ica')"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-eye</v-icon
                            >
                            <v-icon
                              @click="downloadFile(fname, 'ica')"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-download</v-icon
                            >
                            <v-icon
                              @click="archiveFile('ica_files', fname)"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-archive</v-icon
                            >
                            <v-dialog
                              v-model="filePreviewDialog"
                              background-color="white"
                              fullscreen
                              :retain-focus="false"
                              transition="slide-y-transition"
                            >
                              <v-card elevation="0" class="tw-w-full tw-h-full">
                                <div
                                  class="tw-w-full tw-h-full tw-flex tw-flex-col"
                                >
                                  <v-toolbar
                                    color="#DDEBEB"
                                    elevation="0"
                                    max-height="64px"
                                  >
                                    <v-toolbar-title></v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                      <v-btn icon @click="closePreviewDialog">
                                        <v-icon>mdi-close</v-icon>
                                      </v-btn>
                                    </v-toolbar-items>
                                  </v-toolbar>

                                  <div
                                    class="tw-flex-1 tw-flex tw-w-full tw-h-full tw-items-center tw-justify-center"
                                  >
                                    <img
                                      class="tw-object-contain"
                                      :src="preview"
                                      @error="handleNoPreview"
                                    />
                                    <div
                                      v-if="no_preview"
                                      class="tw-flex-col tw-text-center font2"
                                    >
                                      <div class="tw-text-xl">
                                        Preview attualmente non disponibile.
                                      </div>
                                      <div class="tw-text-xl tw-mb-4">
                                        Vuoi scaricare il file?
                                      </div>
                                      <primary-btn
                                        @click.native="downloadRequestedFile"
                                        class="tw-ml-4"
                                        text="Scarica file"
                                        icon="mdi-upload"
                                      ></primary-btn>
                                    </div>
                                  </div>
                                </div>
                              </v-card>
                            </v-dialog>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col">
                    <div class="tw-mb-8 tw-flex tw-items-center">
                      <div class="tw-text-xl">Mastrini grafici</div>
                      <primary-btn
                        :disabled="isLocked"
                        @click.native="
                          openUploadFileDialog('ica-file', 'mastrino')
                        "
                        class="tw-ml-4 tw-mr-2"
                        text="Carica file"
                        icon="mdi-upload"
                      ></primary-btn>
                    </div>
                    <div class="file-list-wrapper tw-flex-1 tw-h-full">
                      <div
                        class="file-list tw-w-full tw-min-h-1/2 tw-max-h-[87%] tw-overflow-y-auto tw-pr-4 tw-box-border md:tw-pl-4"
                      >
                        <div
                          v-for="(fname, index) in mastrini"
                          :key="index"
                          class="file-obj tw-full tw-flex tw-bg-grey tw-box-border tw-p-4 tw-rounded-xl tw-items-center tw-mb-4 tw-relative"
                        >
                          <div class="tw-mr-2 tw-flex tw-items-center">
                            <v-checkbox
                              v-model="selected_ica_files"
                              :value="fname"
                            ></v-checkbox>
                          </div>
                          <div class="">
                            <img
                              class="tw-16 tw-h-16"
                              :src="
                                path +
                                '/static/img/file_ico/' +
                                getFileIco(fname.name) +
                                '.png'
                              "
                              alt=""
                            />
                          </div>

                          <div class="tw-flex-1 tw-flex-col font2">
                            <p class="tw-font-bold tw-text-sm md:tw-text-md">
                              {{ fname.name.replace(/_/g, " ") }}
                            </p>
                            <p class="tw-text-xs">
                              {{ (fname.size / (1024 * 1024)).toFixed(2) }} MB
                            </p>
                          </div>
                          <div class="tw-flex tw-bg-red">
                            <!-- <v-icon v-if="role == 'commerciale' && project.id_esterno" @click="transferData('ica_file',fname)" class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue">mdi-send</v-icon> -->

                            <v-icon
                              @click="shareFiles(fname.name, 'ica')"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-eye</v-icon
                            >
                            <v-icon
                              @click="downloadFile(fname, 'ica')"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-download</v-icon
                            >
                            <v-icon
                              @click="archiveFile('ica_files', fname)"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-archive</v-icon
                            >

                            <v-dialog
                              v-model="filePreviewDialog"
                              background-color="white"
                              fullscreen
                              :retain-focus="false"
                              transition="slide-y-transition"
                            >
                              <v-card elevation="0" class="tw-w-full tw-h-full">
                                <div
                                  class="tw-w-full tw-h-full tw-flex tw-flex-col"
                                >
                                  <v-toolbar
                                    color="#DDEBEB"
                                    elevation="0"
                                    max-height="64px"
                                  >
                                    <v-toolbar-title></v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                      <v-btn icon @click="closePreviewDialog">
                                        <v-icon>mdi-close</v-icon>
                                      </v-btn>
                                    </v-toolbar-items>
                                  </v-toolbar>

                                  <div
                                    class="tw-flex-1 tw-flex tw-w-full wt-h-full tw-items-center tw-justify-center"
                                  >
                                    <img
                                      class="tw-object-contain"
                                      :src="preview"
                                      @error="handleNoPreview"
                                    />
                                    <div
                                      v-if="no_preview"
                                      class="tw-flex-col tw-text-center font2"
                                    >
                                      <div class="tw-text-xl">
                                        Preview attualmente non disponibile.
                                      </div>
                                      <div class="tw-text-xl tw-mb-4">
                                        Vuoi scaricare il file?
                                      </div>
                                      <primary-btn
                                        @click.native="downloadRequestedFile"
                                        class="tw-ml-4"
                                        text="Scarica file"
                                        icon="mdi-upload"
                                      ></primary-btn>
                                    </div>
                                  </div>
                                </div>
                              </v-card>
                            </v-dialog>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-3'">
            <v-card
              flat
              class="tw-h-full md:tw-overflow-y-hidden tw-overflow-y-auto !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border md:tw-p-6 tw-p-4"
            >
              <v-dialog
                :retain-focus="false"
                v-model="fileDialog"
                width="600"
                background-color="white"
              >
                <v-row class="wh">
                  <v-col cols="12">
                    <v-card elevation="0">
                      <div
                        v-if="form_file == 'ica-file'"
                        class="tw-font-semibold tw-text-2xl tw-my-4"
                      >
                        Allegati Cliente
                      </div>
                      <div v-else class="tw-text-xl tw-font-bold">
                        Allegati Cliente
                      </div>
                      <div class="tw-flex tw-flex-col">
                        <div
                          v-for="(file, index) in current_uploading"
                          :key="index"
                          class="tw-w-full tw-flex tw-flex-col tw-bg-grey tw-mb-2 tw-rounded-xl tw-p-4"
                        >
                          <div class="tw-text-md tw-font-medium">
                            {{ file.name }}
                          </div>
                          <div class="tw-text-sm">{{ file.size }}MB</div>
                          <v-progress-linear
                            v-if="uploading != 100"
                            color="#11797B"
                            class="tw-mt-2"
                            rounded
                            :value="uploading"
                          ></v-progress-linear>
                          <v-progress-linear
                            v-else
                            color="green"
                            class="tw-mt-2"
                            rounded
                            :value="uploading"
                          ></v-progress-linear>
                        </div>
                      </div>
                      <v-col
                        sm="12"
                        class="input-ica tw-h-72 tw-mt-8 !tw-rounded-xl"
                      >
                        <div class="upload-icon">
                          <v-icon color="primary" dark>mdi-upload </v-icon>
                          <p style="font-size: 13px">
                            Clicca o trascina i file qui per caricarli
                          </p>
                        </div>
                        <input
                          v-if="form_file == 'ica-file'"
                          @input="uploadFiles"
                          ref="ica_files"
                          id="upload-false_ica-file"
                          :name="form_file"
                          type="file"
                          size="1"
                          multiple
                        />
                        <input
                          v-else
                          @input="uploadFiles"
                          ref="customer_files"
                          id="upload-false_customer-file"
                          :name="form_file"
                          type="file"
                          size="1"
                          multiple
                        />
                      </v-col>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="closeFileDialog"
                          :loading="isLoading.files"
                        >
                          Chiudi
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-dialog>
              <div
                class="tw-w-full tw-flex md:tw-flex-row md:tw-flex-wrap tw-flex-col tw-h-full"
              >
                <div class="tw-w-full tw-flex tw-flex-col">
                  <div class="tw-mb-8 tw-flex tw-items-center">
                    <div class="tw-font-semibold tw-text-2xl">
                      Altri allegati ({{ customer_files.length }})
                    </div>
                    <v-menu
                      v-model="multiple_customer_files"
                      offset-y
                      content-class="!tw-shadow-xl !tw-rounded-lg tw-w-auto tw-w-min-0 "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <secondary-btn
                          :loading="isLoading.actions"
                          v-bind="attrs"
                          v-on="on"
                          @click.native="multiple_customer_files = true"
                          id="action-btn"
                          class="tw-self-baseline tw-ml-2"
                          text="Azioni multiple"
                          icon="mdi-menu-down"
                        ></secondary-btn>
                      </template>
                      <v-list class="font2">
                        <action-element
                          color="green"
                          @click.native="
                            bulkFilesAction('download', 'customer')
                          "
                          text="Scarica"
                          icon="mdi-download"
                        ></action-element>
                        <action-element
                          color="red"
                          @click.native="bulkFilesAction('delete', 'customer')"
                          text="Cancella"
                          icon="mdi-delete"
                        ></action-element>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
                <div
                  class="tw-w-full tw-flex md:tw-flex-row tw-flex-col tw-h-full"
                >
                  <div class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col">
                    <div class="tw-mb-8 tw-flex tw-items-center">
                      <div class="tw-text-xl">Allegati cliente</div>
                      <primary-btn
                        @click.native="
                          openUploadFileDialog('customer-file', 'customer')
                        "
                        :disabled="isLocked"
                        class="tw-ml-4 tw-mr-2"
                        text="Carica file"
                        icon="mdi-upload"
                      ></primary-btn>
                    </div>
                    <div class="file-list-wrapper tw-flex-1 tw-h-full">
                      <div
                        class="file-list tw-w-full tw-min-h-1/2 tw-max-h-[87%] tw-overflow-y-auto tw-pr-4 tw-box-border"
                      >
                        <div
                          v-for="(fname, index) in customer_files"
                          :key="index"
                          class="file-obj tw-full tw-flex tw-bg-grey tw-box-border tw-p-4 tw-rounded-xl tw-items-center tw-mb-4 tw-relative"
                        >
                          <div class="tw-mr-2 tw-flex tw-items-center">
                            <v-checkbox
                              v-model="selected_customer_files"
                              :value="fname"
                            ></v-checkbox>
                          </div>
                          <div class="">
                            <img
                              class="tw-16 tw-h-16"
                              :src="
                                path +
                                '/static/img/file_ico/' +
                                getFileIco(fname.name) +
                                '.png'
                              "
                              alt=""
                            />
                          </div>

                          <div class="tw-flex-1 tw-flex-col font2">
                            <p class="tw-font-bold tw-text-sm md:tw-text-md">
                              {{ fname.name.replace(/_/g, " ") }}
                            </p>
                            <p class="tw-text-xs">
                              {{ (fname.size / (1024 * 1024)).toFixed(2) }} MB
                            </p>
                          </div>
                          <div class="tw-flex tw-bg-red">
                            <!-- <v-icon v-if="role == 'commerciale' && project.id_esterno" @click="transferData('ica_file',fname)" class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue">mdi-send</v-icon> -->
                            <v-icon
                              @click="shareFiles(fname.name, 'customer')"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-eye</v-icon
                            >
                            <v-icon
                              @click="downloadFile(fname, 'customer')"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-download</v-icon
                            >
                            <v-icon
                              @click="archiveFile('customer_files', fname)"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-archive</v-icon
                            >
                            <v-dialog
                              v-model="filePreviewDialog"
                              background-color="white"
                              fullscreen
                              :retain-focus="false"
                              transition="slide-y-transition"
                            >
                              <v-card elevation="0" class="tw-w-full tw-h-full">
                                <div
                                  class="tw-w-full tw-h-full tw-flex tw-flex-col"
                                >
                                  <v-toolbar
                                    color="#DDEBEB"
                                    elevation="0"
                                    max-height="64px"
                                  >
                                    <v-toolbar-title></v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                      <v-btn icon @click="closePreviewDialog">
                                        <v-icon>mdi-close</v-icon>
                                      </v-btn>
                                    </v-toolbar-items>
                                  </v-toolbar>

                                  <div
                                    class="tw-flex-1 tw-flex tw-w-full wt-h-full tw-items-center tw-justify-center"
                                  >
                                    <img
                                      class="tw-object-contain"
                                      :src="preview"
                                      @error="handleNoPreview"
                                    />
                                    <div
                                      v-if="no_preview"
                                      class="tw-flex-col tw-text-center font2"
                                    >
                                      <div class="tw-text-xl">
                                        Preview attualmente non disponibile.
                                      </div>
                                      <div class="tw-text-xl tw-mb-4">
                                        Vuoi scaricare il file?
                                      </div>
                                      <primary-btn
                                        @click.native="downloadRequestedFile"
                                        class="tw-ml-4"
                                        text="Scarica file"
                                        icon="mdi-upload"
                                      ></primary-btn>
                                    </div>
                                  </div>
                                </div>
                              </v-card>
                            </v-dialog>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col">
                    <div class="tw-mb-8 tw-flex tw-items-center">
                      <div class="tw-text-xl">3D</div>
                      <primary-btn
                        :disabled="isLocked"
                        @click.native="
                          openUploadFileDialog('customer-file', '3d')
                        "
                        class="tw-ml-4 tw-mr-2"
                        text="Carica file"
                        icon="mdi-upload"
                      ></primary-btn>
                    </div>
                    <div class="file-list-wrapper tw-flex-1 tw-h-full">
                      <div
                        class="file-list tw-w-full tw-min-h-1/2 tw-max-h-[87%] tw-overflow-y-auto tw-pr-4 tw-box-border md:tw-pl-4"
                      >
                        <div
                          v-for="(fname, index) in three_ds"
                          :key="index"
                          class="file-obj tw-full tw-flex tw-bg-grey tw-box-border tw-p-4 tw-rounded-xl tw-items-center tw-mb-4 tw-relative"
                        >
                          <div class="tw-mr-2 tw-flex tw-items-center">
                            <v-checkbox
                              v-model="selected_customer_files"
                              :value="fname"
                            ></v-checkbox>
                          </div>
                          <div class="">
                            <img
                              class="tw-16 tw-h-16"
                              :src="
                                path +
                                '/static/img/file_ico/' +
                                getFileIco(fname.name) +
                                '.png'
                              "
                              alt=""
                            />
                          </div>

                          <div class="tw-flex-1 tw-flex-col font2">
                            <p class="tw-font-bold tw-text-sm md:tw-text-md">
                              {{ fname.name.replace(/_/g, " ") }}
                            </p>
                            <p class="tw-text-xs">
                              {{ (fname.size / (1024 * 1024)).toFixed(2) }} MB
                            </p>
                          </div>
                          <div class="tw-flex tw-bg-red">
                            <!-- <v-icon v-if="role == 'commerciale' && project.id_esterno" @click="transferData('ica_file',fname)" class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue">mdi-send</v-icon> -->
                            <v-icon
                              @click="shareFiles(fname.name, 'customer')"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-eye</v-icon
                            >
                            <v-icon
                              @click="downloadFile(fname, 'customer')"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-download</v-icon
                            >
                            <v-icon
                              @click="deleteFile(fname, 'customer')"
                              class="tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-red"
                              >mdi-delete</v-icon
                            >
                            <v-dialog
                              v-model="filePreviewDialog"
                              background-color="white"
                              fullscreen
                              :retain-focus="false"
                              transition="slide-y-transition"
                            >
                              <v-card elevation="0" class="tw-w-full tw-h-full">
                                <div
                                  class="tw-w-full tw-h-full tw-flex tw-flex-col"
                                >
                                  <v-toolbar
                                    color="#DDEBEB"
                                    elevation="0"
                                    max-height="64px"
                                  >
                                    <v-toolbar-title></v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                      <v-btn icon @click="closePreviewDialog">
                                        <v-icon>mdi-close</v-icon>
                                      </v-btn>
                                    </v-toolbar-items>
                                  </v-toolbar>

                                  <div
                                    class="tw-flex-1 tw-flex tw-w-full wt-h-full tw-items-center tw-justify-center"
                                  >
                                    <img
                                      class="tw-object-contain"
                                      :src="preview"
                                      @error="handleNoPreview"
                                    />
                                    <div
                                      v-if="no_preview"
                                      class="tw-flex-col tw-text-center font2"
                                    >
                                      <div class="tw-text-xl">
                                        Preview attualmente non disponibile.
                                      </div>
                                      <div class="tw-text-xl tw-mb-4">
                                        Vuoi scaricare il file?
                                      </div>
                                      <primary-btn
                                        @click.native="downloadRequestedFile"
                                        class="tw-ml-4"
                                        text="Scarica file"
                                        icon="mdi-upload"
                                      ></primary-btn>
                                    </div>
                                  </div>
                                </div>
                              </v-card>
                            </v-dialog>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-12'">
            <v-card
              flat
              class="tw-h-full md:tw-overflow-y-hidden tw-overflow-y-auto !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border md:tw-p-6 tw-p-4"
            >
              <div class="tw-w-full tw-flex tw-flex-col tw-h-full">
                <div class="tw-w-full tw-flex tw-flex-col">
                  <div class="tw-mb-8 tw-flex tw-items-center">
                    <div class="tw-font-semibold tw-text-2xl">
                      Allegati Archiviati ({{
                        archivedMastrini.length + archivedSchede.length
                      }})
                    </div>
                  </div>
                </div>
                <div
                  class="tw-w-full tw-flex md:tw-flex-row tw-flex-col tw-h-full"
                >
                  <div class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col">
                    <div class="tw-mb-8 tw-flex tw-items-center">
                      <div class="tw-text-xl">Schede tecniche</div>
                    </div>
                    <div class="file-list-wrapper tw-flex-1 tw-h-full">
                      <div
                        class="file-list tw-w-full tw-min-h-1/2 tw-max-h-[87%] tw-overflow-y-scroll md:tw-pr-4 tw-box-border"
                      >
                        <div
                          v-for="(fname, index) in archivedSchede"
                          :key="index"
                          class="file-obj tw-full tw-flex tw-bg-grey tw-box-border tw-p-4 tw-rounded-xl tw-items-center tw-mb-4 tw-relative"
                        >
                          <div class="tw-mr-2 tw-flex tw-items-center">
                            <v-checkbox
                              v-model="selected_ica_files"
                              :value="fname"
                            ></v-checkbox>
                          </div>
                          <div class="">
                            <img
                              class="tw-16 tw-h-16"
                              :src="
                                path +
                                '/static/img/file_ico/' +
                                getFileIco(fname.name) +
                                '.png'
                              "
                              alt=""
                            />
                          </div>
                          <div class="tw-flex-1 tw-flex-col font2">
                            <p class="tw-font-bold tw-text-sm md:tw-text-md">
                              {{ fname.name.replace(/_/g, " ") }}
                            </p>
                            <p class="tw-text-xs">
                              {{ (fname.size / (1024 * 1024)).toFixed(2) }} MB
                            </p>
                          </div>
                          <div class="tw-flex tw-bg-red">
                            <v-icon
                              @click="recoverFile('ica_files', fname)"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-restore</v-icon
                            >
                            <v-icon
                              @click="downloadFile(fname, 'ica')"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-download</v-icon
                            >
                            <v-icon
                              @click="deleteFile(fname, 'ica')"
                              class="tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-red"
                              >mdi-delete</v-icon
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col">
                    <div class="tw-mb-8 tw-flex tw-items-center">
                      <div class="tw-text-xl">Mastrini grafici</div>
                    </div>
                    <div class="file-list-wrapper tw-flex-1 tw-h-full">
                      <div
                        class="file-list tw-w-full tw-min-h-1/2 tw-max-h-[87%] tw-overflow-y-auto tw-pr-4 tw-box-border md:tw-pl-4"
                      >
                        <div
                          v-for="(fname, index) in archivedMastrini"
                          :key="index"
                          class="file-obj tw-full tw-flex tw-bg-grey tw-box-border tw-p-4 tw-rounded-xl tw-items-center tw-mb-4 tw-relative"
                        >
                          <div class="tw-mr-2 tw-flex tw-items-center">
                            <v-checkbox
                              v-model="selected_ica_files"
                              :value="fname"
                            ></v-checkbox>
                          </div>
                          <div class="">
                            <img
                              class="tw-16 tw-h-16"
                              :src="
                                path +
                                '/static/img/file_ico/' +
                                getFileIco(fname.name) +
                                '.png'
                              "
                              alt=""
                            />
                          </div>

                          <div class="tw-flex-1 tw-flex-col font2">
                            <p class="tw-font-bold tw-text-sm md:tw-text-md">
                              {{ fname.name.replace(/_/g, " ") }}
                            </p>
                            <p class="tw-text-xs">
                              {{ (fname.size / (1024 * 1024)).toFixed(2) }} MB
                            </p>
                          </div>
                          <div class="tw-flex tw-bg-red">
                            <v-icon
                              @click="recoverFile('ica_files', fname)"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-restore</v-icon
                            >
                            <v-icon
                              @click="downloadFile(fname, 'ica')"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-download</v-icon
                            >
                            <v-icon
                              @click="deleteFile(fname, 'ica')"
                              class="tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-red"
                              >mdi-delete</v-icon
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-13'">
            <v-card
              flat
              class="tw-h-full md:tw-overflow-y-hidden tw-overflow-y-auto !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border md:tw-p-6 tw-p-4"
            >
              <div class="tw-w-full tw-flex tw-flex-col tw-h-full">
                <div class="tw-w-full tw-flex tw-flex-col">
                  <div class="tw-mb-8 tw-flex tw-items-center">
                    <div class="tw-font-semibold tw-text-2xl">
                      Allegati Archiviati ({{
                        archivedCustomer.length + archivedThreeD.length
                      }})
                    </div>
                  </div>
                </div>
                <div
                  class="tw-w-full tw-flex md:tw-flex-row tw-flex-col tw-h-full"
                >
                  <div class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col">
                    <div class="tw-mb-8 tw-flex tw-items-center">
                      <div class="tw-text-xl">Allegati Cliente</div>
                    </div>
                    <div class="file-list-wrapper tw-flex-1 tw-h-full">
                      <div
                        class="file-list tw-w-full tw-min-h-1/2 tw-max-h-[87%] tw-overflow-y-scroll md:tw-pr-4 tw-box-border"
                      >
                        <div
                          v-for="(fname, index) in archivedCustomer"
                          :key="index"
                          class="file-obj tw-full tw-flex tw-bg-grey tw-box-border tw-p-4 tw-rounded-xl tw-items-center tw-mb-4 tw-relative"
                        >
                          <div class="tw-mr-2 tw-flex tw-items-center">
                            <v-checkbox
                              v-model="selected_customer_files"
                              :value="fname"
                            ></v-checkbox>
                          </div>
                          <div class="">
                            <img
                              class="tw-16 tw-h-16"
                              :src="
                                path +
                                '/static/img/file_ico/' +
                                getFileIco(fname.name) +
                                '.png'
                              "
                              alt=""
                            />
                          </div>
                          <div class="tw-flex-1 tw-flex-col font2">
                            <p class="tw-font-bold tw-text-sm md:tw-text-md">
                              {{ fname.name.replace(/_/g, " ") }}
                            </p>
                            <p class="tw-text-xs">
                              {{ (fname.size / (1024 * 1024)).toFixed(2) }} MB
                            </p>
                          </div>
                          <div class="tw-flex tw-bg-red">
                            <v-icon
                              @click="recoverFile('customer_files', fname)"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-restore</v-icon
                            >
                            <v-icon
                              @click="downloadFile(fname, 'customer')"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-download</v-icon
                            >
                            <v-icon
                              @click="deleteFile(fname, 'customer')"
                              class="tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-red"
                              >mdi-delete</v-icon
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col">
                    <div class="tw-mb-8 tw-flex tw-items-center">
                      <div class="tw-text-xl">3D</div>
                    </div>
                    <div class="file-list-wrapper tw-flex-1 tw-h-full">
                      <div
                        class="file-list tw-w-full tw-min-h-1/2 tw-max-h-[87%] tw-overflow-y-auto tw-pr-4 tw-box-border md:tw-pl-4"
                      >
                        <div
                          v-for="(fname, index) in archivedThreeD"
                          :key="index"
                          class="file-obj tw-full tw-flex tw-bg-grey tw-box-border tw-p-4 tw-rounded-xl tw-items-center tw-mb-4 tw-relative"
                        >
                          <div class="tw-mr-2 tw-flex tw-items-center">
                            <v-checkbox
                              v-model="selected_customer_files"
                              :value="fname"
                            ></v-checkbox>
                          </div>
                          <div class="">
                            <img
                              class="tw-16 tw-h-16"
                              :src="
                                path +
                                '/static/img/file_ico/' +
                                getFileIco(fname.name) +
                                '.png'
                              "
                              alt=""
                            />
                          </div>

                          <div class="tw-flex-1 tw-flex-col font2">
                            <p class="tw-font-bold tw-text-sm md:tw-text-md">
                              {{ fname.name.replace(/_/g, " ") }}
                            </p>
                            <p class="tw-text-xs">
                              {{ (fname.size / (1024 * 1024)).toFixed(2) }} MB
                            </p>
                          </div>
                          <div class="tw-flex tw-bg-red">
                            <v-icon
                              @click="recoverFile('customer_files', fname)"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-restore</v-icon
                            >
                            <v-icon
                              @click="downloadFile(fname, 'customer')"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-download</v-icon
                            >
                            <v-icon
                              @click="deleteFile(fname, 'customer')"
                              class="tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-red"
                              >mdi-delete</v-icon
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-4'">
            <v-card
              flat
              class="tw-h-full !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border tw-p-4 md:tw-p-6 md:tw-overflow-y-auto tw-overflow-y-scroll"
            >
              <div
                class="tw-m-0 tw-p-0 tw-flex md:!tw-flex-row !tw-flex-col !tw-flex-nowrap tw-h-full"
              >
                <div
                  class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col tw-mb-8 md:tw-mb-0"
                >
                  <div class="tw-mb-8 tw-flex tw-items-center">
                    <div class="tw-flex">
                      <div class="tw-font-semibold md:tw-text-2xl tw-text-lg">
                        Preventivi Fustella (vecchio sistema)
                        <span
                          v-if="
                            project.preventivi_fustella &&
                            project.preventivi_fustella.length > 0
                          "
                          >({{ project.preventivi_fustella.length }})</span
                        >
                      </div>
                      <primary-btn
                        v-if="user.role == 'commerciale'"
                        @click.native="openPrevDialog('preventivi_fustella')"
                        class="tw-ml-4"
                        text="Nuovo"
                        icon="mdi-plus"
                      ></primary-btn>
                    </div>
                  </div>
                  <div
                    class="tw-flex-1 tw-h-full tw-w-full md:tw-overflow-y-hidden tw-overflow-y-scroll"
                  >
                    <div
                      class="md:tw-ml-8"
                      v-if="
                        project.preventivi_fustella &&
                        project.preventivi_fustella.length == 0
                      "
                    >
                      <p>Non ci sono preventivi fustella...</p>
                    </div>
                    <div class="file-list-wrapper tw-flex-1 tw-h-full">
                      <div
                        v-if="
                          project.preventivi_fustella &&
                          project.preventivi_fustella.length > 0
                        "
                        class="tw-flex tw-flex-col tw-max-h-full tw-overflow-y-auto tw-pl-8"
                      >
                        <v-row
                          class="tw-box-border tw-p-4 tw-mt-2 tw-mb-2 tw-rounded-xl tw-bg-grey tw-w-full"
                          v-for="(
                            quotation, index
                          ) in project.preventivi_fustella"
                          :key="index"
                        >
                          <v-col class="nopd center_y" sm="5">
                            <div style="font-size: 16px">
                              <span><b>Fornitore: </b></span
                              >{{ quotation.machine_supplier }}
                            </div>
                          </v-col>

                          <v-col
                            class="nopd center_y"
                            sm="6"
                            v-if="quotation.is_editing && user.role == 'master'"
                          >
                            <custom-select
                              placeholder="Seleziona il tipo di macchina"
                              :clearable="true"
                              label="name"
                              :options="[
                                'Piana',
                                'Rotativa',
                                'Casemaker',
                                'Platina',
                              ]"
                              :disabled="isDisabled"
                              required
                              class="!tw-bg-white tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                              v-model="quotation.machine_type"
                            >
                            </custom-select>
                          </v-col>
                          <v-col class="nopd center_y" sm="4" v-else>
                            <div style="font-size: 16px">
                              <b>Macchina: </b>{{ quotation.machine_type }}
                            </div>
                          </v-col>

                          <v-col
                            class="nopd center_y"
                            sm="6"
                            v-if="quotation.is_editing && user.role == 'master'"
                          >
                            <input-field
                              :darker="true"
                              class="tw-w-full"
                              type="number"
                              v-model="quotation.resa"
                              placeholder="Resa"
                              :disabled="isDisabled"
                              required
                            ></input-field>
                          </v-col>

                          <v-col class="nopd center_y" sm="3" v-else>
                            <div style="font-size: 16px">
                              <b>Resa: </b>{{ quotation.resa }}
                            </div>
                          </v-col>
                          <v-col
                            v-if="
                              quotation.suppliers &&
                              quotation.suppliers.length > 0
                            "
                            cols="12"
                            class="nopd tw-mt-4"
                          >
                            <v-row
                              v-for="(supplier, index3) in quotation.suppliers"
                              :key="index3"
                              class="tw-flex tw-my-2 tw-p-2 tw-px-4 tw-bg-white tw-rounded-xl tw-mx-auto tw-flex-col md:tw-flex-row"
                              width="100%"
                            >
                              <v-col
                                style="font-size: 14px"
                                class="nopd d-flex center_y"
                                cols="12"
                                md="5"
                              >
                                <span style="margin-right: 3px"
                                  ><b>Fornitore: </b></span
                                >
                                {{ supplier.supplier }}
                              </v-col>
                              <v-col
                                style="font-size: 14px"
                                class="nopd d-flex quotation.suppliers && center_y"
                                cols="12"
                                md="5"
                              >
                                <span
                                  v-if="supplier.price && !supplier.is_editing"
                                  style="margin-right: 3px"
                                  ><b>Prezzo: € </b>{{ supplier.price }}</span
                                >
                                <span
                                  class="tw-flex tw-items-center tw-justify-center"
                                  v-else-if="
                                    user.role == 'master' && supplier.is_editing
                                  "
                                >
                                  <b>Prezzo: </b> €
                                  <input-field
                                    :darker="true"
                                    class="tw-w-28 tw-ml-2 tw-h-full"
                                    type="number"
                                    v-model="supplier.price"
                                    placeholder="0"
                                    :disabled="isDisabled"
                                    required
                                  ></input-field>
                                </span>
                                <span
                                  v-else-if="
                                    !supplier.price && supplier.status == 0
                                  "
                                  >In attesa</span
                                >
                              </v-col>
                              <v-col
                                style="font-size: 14px"
                                class="nopd d-flex center_y"
                                cols="12"
                                md="2"
                              >
                                <span style="margin-right: 3px"
                                  ><b>Stato: </b></span
                                >
                                <span
                                  class="dot"
                                  v-bind:style="{
                                    backgroundColor: getQuotationColor(
                                      supplier.status
                                    ),
                                  }"
                                ></span>
                              </v-col>
                              <v-col
                                style="font-size: 14px"
                                cols="12"
                                class="!tw-p-0"
                              >
                                <v-col
                                  v-if="
                                    supplier.is_editing &&
                                    user.role == 'master' &&
                                    supplier.status != 0
                                  "
                                  cols="12"
                                  class="tw-p-0"
                                >
                                  <div class="tw-mb-2">
                                    <b>Note del grafico: </b>
                                  </div>
                                  <textarea
                                    v-model="supplier.master_notes"
                                    class="tw-bg-grey tw-rounded-xl tw-w-full tw-h-32 tw-box-border tw-p-4"
                                  ></textarea>
                                </v-col>
                                <v-col
                                  v-else-if="supplier.master_notes"
                                  cols="12"
                                  class="tw-p-0"
                                >
                                  <div class="tw-mt-2">
                                    <b>Note del grafico: </b>
                                  </div>
                                  <div>{{ supplier.master_notes }}</div>
                                </v-col>
                              </v-col>
                              <v-col
                                style="font-size: 14px"
                                class="tw-p-0 tw-flex tw-justify-center tw-items-center"
                                cols="12"
                              >
                                <span class="tw-mt-2">
                                  <v-btn
                                    @click="
                                      updateQuotation(
                                        index,
                                        'preventivi_fustella',
                                        index3,
                                        1
                                      )
                                    "
                                    v-if="
                                      !supplier.price &&
                                      supplier.status == 0 &&
                                      user.role == 'master' &&
                                      !supplier.is_editing
                                    "
                                    icon
                                    :loading="
                                      isLoading.quotation.fustella.update
                                    "
                                  >
                                    <v-icon
                                      class="hover:tw-opacity-80 !tw-text-custom-blue tw-cursor-pointer"
                                      >mdi-send-circle</v-icon
                                    >
                                  </v-btn>
                                  <v-icon
                                    v-else-if="
                                      user.role == 'master' &&
                                      !supplier.is_editing
                                    "
                                    @click="supplier.is_editing = true"
                                    class="hover:tw-opacity-80 !tw-text-custom-blue tw-cursor-pointer"
                                    >mdi-pencil-circle</v-icon
                                  >
                                  <v-btn
                                    icon
                                    :loading="
                                      isLoading.quotation.fustella.update
                                    "
                                    v-else-if="
                                      user.role == 'master' &&
                                      supplier.is_editing
                                    "
                                    @click="
                                      updateQuotation(
                                        index,
                                        'preventivi_fustella',
                                        index3,
                                        2
                                      )
                                    "
                                  >
                                    <v-icon
                                      class="hover:tw-opacity-80 !tw-text-green tw-cursor-pointer"
                                      >mdi-check-circle</v-icon
                                    >
                                  </v-btn>
                                </span>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col class="center_y tw-p-0 tw-mt-4" sm="12">
                            <div
                              v-if="quotation.referrer_notes"
                              style="margin-top: 5px; font-size: 16px"
                            >
                              <b>Note del commerciale: <br /> </b
                              >{{ quotation.referrer_notes }}
                            </div>
                          </v-col>
                          <v-col class="nopd center_y mg-10" cols="12">
                            <v-col class="nopd center">
                              <v-icon
                                size="30"
                                class="action-file"
                                @click="
                                  deleteQuotation(index, 'preventivi_fustella')
                                "
                                color="red"
                                v-if="user.role == 'commerciale'"
                              >
                                mdi-delete-circle
                              </v-icon>
                            </v-col>
                          </v-col>
                          <v-col class="nopd center_y" sm="6">
                            <v-col class="nopd center"> </v-col>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col">
                  <div class="tw-mb-8 tw-flex tw-items-center">
                    <div class="tw-flex">
                      <div class="tw-font-semibold md:tw-text-2xl tw-text-lg">
                        Preventivi Impianti Stampa (vecchio sistema)<span
                          v-if="
                            project.preventivi_stampa &&
                            project.preventivi_stampa.length > 0
                          "
                          >({{ project.preventivi_stampa.length }})</span
                        >
                      </div>
                      <primary-btn
                        v-if="user.role == 'commerciale'"
                        @click.native="openPrevDialog('preventivi_stampa')"
                        class="tw-ml-4"
                        text="Nuovo"
                        icon="mdi-plus"
                      ></primary-btn>
                    </div>
                  </div>
                  <div
                    class="tw-flex-1 tw-h-full tw-w-full md:tw-overflow-y-hidden tw-overflow-y-scroll"
                  >
                    <div
                      class="md:tw-ml-8"
                      v-if="
                        project.preventivi_stampa &&
                        project.preventivi_stampa.length == 0
                      "
                    >
                      <p>Non ci sono preventivi Impianti stampa...</p>
                    </div>
                    <div
                      v-if="
                        project.preventivi_stampa &&
                        project.preventivi_stampa.length > 0
                      "
                      class="tw-flex tw-flex-col tw-max-h-full md:tw-overflow-y-auto tw-overflow-y-scroll md:tw-pl-8 tw-pl-4"
                    >
                      <v-row
                        class="tw-box-border tw-p-4 tw-mt-2 tw-mb-2 tw-rounded-xl tw-bg-grey tw-w-full"
                        v-for="(quotation, index) in project.preventivi_stampa"
                        :key="index"
                      >
                        <v-col class="nopd center_y" sm="5">
                          <div style="font-size: 16px">
                            <span><b>Fornitore: </b></span
                            >{{ quotation.machine_supplier }}
                          </div>
                        </v-col>

                        <v-col
                          class="nopd center_y"
                          sm="6"
                          v-if="quotation.is_editing && user.role == 'master'"
                        >
                          <custom-select
                            placeholder="Seleziona il materiale"
                            :clearable="true"
                            label="name"
                            :options="[
                              'Solido Analogico',
                              'Solido Digitale',
                              'Liquido',
                            ]"
                            :disabled="isDisabled"
                            required
                            class="!tw-bg-grey tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                            v-model="quotation.tp"
                          >
                          </custom-select>
                        </v-col>
                        <v-col class="nopd center_y" sm="4" v-else>
                          <div style="font-size: 16px">
                            <b>Materiale: </b>{{ quotation.tp }}
                          </div>
                        </v-col>

                        <v-col
                          class="nopd center_y"
                          sm="6"
                          v-if="quotation.is_editing && user.role == 'master'"
                        >
                          <input-field
                            :darker="true"
                            class="tw-w-full"
                            type="number"
                            v-model="quotation.resa"
                            placeholder="Resa"
                            :disabled="isDisabled"
                            required
                          ></input-field>
                        </v-col>

                        <v-col class="nopd center_y" sm="3" v-else>
                          <div style="font-size: 16px">
                            <b>Resa: </b>{{ quotation.resa }}
                          </div>
                        </v-col>
                        <v-col
                          v-if="
                            quotation.suppliers &&
                            quotation.suppliers.length > 0
                          "
                          cols="12"
                          class="nopd tw-mt-4"
                        >
                          <v-row
                            v-for="(supplier, index3) in quotation.suppliers"
                            :key="index3"
                            class="tw-flex tw-my-2 tw-p-2 tw-px-4 tw-bg-white tw-rounded-xl tw-mx-auto tw-flex-col md:tw-flex-row"
                            width="100%"
                          >
                            <v-col
                              style="font-size: 14px"
                              class="nopd d-flex center_y"
                              cols="12"
                              md="5"
                            >
                              <span style="margin-right: 3px"
                                ><b>Fornitore: </b></span
                              >
                              {{ supplier.supplier }}
                            </v-col>
                            <v-col
                              style="font-size: 14px"
                              class="nopd d-flex quotation.suppliers && center_y"
                              cols="12"
                              md="5"
                            >
                              <span
                                v-if="supplier.price && !supplier.is_editing"
                                style="margin-right: 3px"
                                ><b>Prezzo: € </b>{{ supplier.price }}</span
                              >
                              <span
                                class="tw-flex tw-items-center tw-justify-center"
                                v-else-if="
                                  user.role == 'master' && supplier.is_editing
                                "
                              >
                                <b>Prezzo: </b> €
                                <input-field
                                  :darker="true"
                                  class="tw-w-28 tw-ml-2 tw-h-full"
                                  type="number"
                                  v-model="supplier.price"
                                  placeholder="0"
                                  :disabled="isDisabled"
                                  required
                                ></input-field>
                              </span>
                              <span
                                v-else-if="
                                  !supplier.price && supplier.status == 0
                                "
                                >In attesa</span
                              >
                            </v-col>
                            <v-col
                              style="font-size: 14px"
                              class="nopd d-flex center_y"
                              cols="12"
                              md="2"
                            >
                              <span style="margin-right: 3px"
                                ><b>Stato: </b></span
                              >
                              <span
                                class="dot"
                                v-bind:style="{
                                  backgroundColor: getQuotationColor(
                                    supplier.status
                                  ),
                                }"
                              ></span>
                            </v-col>
                            <v-col
                              style="font-size: 14px"
                              cols="12"
                              class="!tw-p-0"
                            >
                              <v-col
                                v-if="
                                  supplier.is_editing &&
                                  user.role == 'master' &&
                                  supplier.status != 0
                                "
                                cols="12"
                                class="tw-p-0"
                              >
                                <div class="tw-mb-2">
                                  <b>Note del grafico: </b>
                                </div>
                                <textarea
                                  v-model="supplier.master_notes"
                                  class="tw-bg-grey tw-rounded-xl tw-w-full tw-h-32 tw-box-border tw-p-4"
                                ></textarea>
                              </v-col>
                              <v-col
                                v-else-if="supplier.master_notes"
                                cols="12"
                                class="tw-p-0"
                              >
                                <div class="tw-mt-2">
                                  <b>Note del grafico: </b>
                                </div>
                                <div>{{ supplier.master_notes }}</div>
                              </v-col>
                            </v-col>
                            <v-col
                              style="font-size: 14px"
                              class="tw-p-0 tw-flex tw-justify-center tw-items-center"
                              cols="12"
                            >
                              <span class="tw-mt-2">
                                <v-btn
                                  :loading="isLoading.quotation.stampa.update"
                                  @click="
                                    updateQuotation(
                                      index,
                                      'preventivi_stampa',
                                      index3,
                                      1
                                    )
                                  "
                                  v-if="
                                    !supplier.price &&
                                    supplier.status == 0 &&
                                    user.role == 'master' &&
                                    !supplier.is_editing
                                  "
                                  icon
                                >
                                  <v-icon
                                    class="hover:tw-opacity-80 !tw-text-custom-blue tw-cursor-pointer"
                                    >mdi-send-circle</v-icon
                                  >
                                </v-btn>
                                <v-icon
                                  v-else-if="
                                    user.role == 'master' &&
                                    !supplier.is_editing
                                  "
                                  @click="supplier.is_editing = true"
                                  class="hover:tw-opacity-80 !tw-text-custom-blue tw-cursor-pointer"
                                  >mdi-pencil-circle</v-icon
                                >
                                <v-btn
                                  :loading="isLoading.quotation.stampa.update"
                                  v-else-if="
                                    user.role == 'master' && supplier.is_editing
                                  "
                                  @click="
                                    updateQuotation(
                                      index,
                                      'preventivi_stampa',
                                      index3,
                                      2
                                    )
                                  "
                                  icon
                                >
                                  <v-icon
                                    class="hover:tw-opacity-80 !tw-text-green tw-cursor-pointer"
                                    >mdi-check-circle</v-icon
                                  >
                                </v-btn>
                              </span>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col class="center_y tw-p-0 tw-mt-4" cols="12">
                          <div
                            v-if="quotation.referrer_notes"
                            style="margin-top: 5px; font-size: 16px"
                          >
                            <b>Note del commerciale: <br /> </b
                            >{{ quotation.referrer_notes }}
                          </div>
                        </v-col>
                        <v-col class="nopd center_y mg-10" cols="12">
                          <v-col class="nopd center">
                            <v-icon
                              size="30"
                              class="action-file"
                              @click="
                                deleteQuotation(index, 'preventivi_stampa')
                              "
                              color="red"
                              v-if="user.role == 'commerciale'"
                            >
                              mdi-delete-circle
                            </v-icon>
                          </v-col>
                        </v-col>
                        <v-col class="nopd center_y" sm="6">
                          <v-col class="nopd center"> </v-col>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-5'">
            <v-card
              flat
              class="tw-h-full !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border tw-p-6"
              v-if="project.payments && project.payments.length > 0"
            >
              <v-row class="time-content">
                <v-col cols="12" class="nopd">
                  <v-card-title
                    >Pagamenti
                    <v-icon
                      style="margin-left: 10px"
                      color="primary"
                      dark
                      class="new-customer"
                      v-if="user.role != 'contabile'"
                      @click="new_deposite_dialog = true"
                      >mdi-plus
                    </v-icon>
                  </v-card-title>
                </v-col>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(payment, index) in project.payments"
                    :key="index"
                  >
                    <v-expansion-panel-header>
                      <span
                        style="
                          width: 45%;
                          box-sizing: border-box;
                          margin-right: 13px;
                        "
                        ><b>Riferimento: </b>{{ payment.name }}</span
                      >
                      <span
                        ><b>Imponibile: </b>€ {{ payment.request.qty }}
                        <b v-if="payment.request.iva"> + IVA</b>
                      </span>
                      <span
                        ><b>Da scalare: </b>€
                        {{ payment.request.to_scale }}</span
                      ><span
                        ><v-chip dark :color="getColor(payment.status)">{{
                          getCurrentStatus(payment.status)
                        }}</v-chip></span
                      ><span>
                        <v-icon @click="deletePayment(index)">
                          mdi-delete
                        </v-icon>
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-timeline align-top dense>
                        <v-timeline-item
                          color="pink"
                          small
                          v-if="payment.request"
                        >
                          <v-row class="pt-1">
                            <v-col cols="3">
                              <strong>{{
                                payment.request.datetime.split(" ")[0]
                              }}</strong>
                              <br />
                              {{ payment.request.datetime.split(" ")[1] }}
                            </v-col>
                            <v-col>
                              <p class="nopd">
                                <strong
                                  >{{ payment.request.requested_by }}
                                </strong>
                                ha richiesto al cliente un pagamento di €
                                {{ payment.request.qty }} <br />
                                (IVA € {{ payment.request.iva }})
                              </p>
                              <p
                                v-if="payment.request.is_scalated == 1"
                                class="nopd"
                              >
                                Nell'ordine è da scalare la somma di €
                                {{ payment.request.to_scale }}
                              </p>
                              <div
                                v-if="
                                  !payment.accountant && payment.status == 0
                                "
                                style="font-size: 14px !important"
                                class="caption"
                              >
                                <v-divider
                                  style="margin-bottom: 10px; margin-top: 10px"
                                ></v-divider>
                                Il cliente ha inviato la contabile?
                                <br />
                                <v-btn
                                  v-if="user.role != 'contabile'"
                                  @click="updatePayment('accountant', index)"
                                  style="margin-top: 10px"
                                  >Conferma</v-btn
                                >
                              </div>
                              <div
                                v-else-if="
                                  !payment.accountant && payment.status == 2
                                "
                                style="font-size: 14px !important"
                                class="caption"
                              ></div>
                            </v-col>
                          </v-row>
                        </v-timeline-item>
                        <v-timeline-item
                          color="pink"
                          small
                          v-if="payment.accountant"
                        >
                          <v-row class="pt-1">
                            <v-col cols="3">
                              <strong>{{
                                payment.accountant.datetime.split(" ")[0]
                              }}</strong>
                              <br />
                              {{ payment.accountant.datetime.split(" ")[1] }}
                            </v-col>
                            <v-col>
                              <p class="nopd">
                                Il cliente ha inviato la contabile
                              </p>
                              <div
                                v-if="payment.accountant && payment.status == 1"
                                style="font-size: 14px !important"
                                class="caption"
                              >
                                <v-divider
                                  style="margin-bottom: 10px; margin-top: 10px"
                                ></v-divider>
                                Il pagamento è stato ricevuto?
                                <br />
                                <v-btn
                                  v-if="user.role == 'contabile'"
                                  @click="updatePayment('verification', index)"
                                  style="margin-top: 10px"
                                  >Conferma</v-btn
                                >
                              </div>
                            </v-col>
                          </v-row>
                        </v-timeline-item>

                        <v-timeline-item
                          color="pink"
                          small
                          v-if="payment.verification"
                        >
                          <v-row class="pt-1">
                            <v-col cols="3">
                              <strong>{{
                                payment.verification.datetime.split(" ")[0]
                              }}</strong>
                              <br />
                              {{ payment.verification.datetime.split(" ")[1] }}
                            </v-col>
                            <v-col>
                              <p class="nopd">il pagamento è stato ricevuto</p>
                            </v-col>
                          </v-row>
                        </v-timeline-item>
                      </v-timeline>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-card>
            <v-card
              flat
              v-else
              class="tw-h-full !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border tw-p-6"
            >
              <v-row class="time-content">
                <v-col cols="12">
                  <v-card-title
                    >Non esistono pagamenti in questo progetto</v-card-title
                  >
                  <v-card-subtitle v-if="user.role != 'contabile'"
                    >Crearne uno?
                    <v-icon
                      style="margin-right: 15px"
                      color="primary"
                      dark
                      class="new-customer"
                      @click="new_deposite_dialog = true"
                      >mdi-plus
                    </v-icon>
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-6'">
            <v-card
              class="tw-h-full !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border tw-p-6"
              flat
            >
              <v-row class="tw-m-0 tw-p-0 tw-flex tw-flex-col tw-h-full">
                <div class="tw-flex">
                  <div class="tw-font-semibold tw-text-2xl">
                    Preventivi Scatola
                    <span
                      v-if="
                        project.preventivi_scatola &&
                        project.preventivi_scatola.length > 0
                      "
                      >({{ project.preventivi_scatola.length }})</span
                    >
                  </div>
                  <primary-btn
                    v-if="user.role == 'commerciale'"
                    @click.native="openPrevDialog('preventivi_scatola')"
                    class="tw-ml-4"
                    text="Nuovo"
                    icon="mdi-plus"
                  ></primary-btn>
                </div>
                <div class="tw-h-full tw-flex-1 tw-w-full tw-overflow-hidden">
                  <div
                    v-if="
                      project.preventivi_scatola &&
                      project.preventivi_scatola.length == 0
                    "
                  >
                    <p>Non ci sono preventivi fustella...</p>
                  </div>
                  <div
                    v-if="
                      project.preventivi_scatola &&
                      project.preventivi_scatola.length > 0
                    "
                    class="tw-flex tw-flex-col tw-max-h-full tw-overflow-y-auto tw-p-8"
                  >
                    <v-row
                      class="tw-flex tw-box-border tw-rounded-xl tw-bg-grey tw-p-4 tw-w-full tw-mb-2"
                      v-for="(quotation, index) in project.preventivi_scatola"
                      :key="index"
                    >
                      <v-col
                        class="nopd center_y"
                        sm="12"
                        v-if="!quotation.is_saved"
                      >
                        <select
                          v-model="quotation.supplier"
                          class="input-block mg-5"
                        >
                          <option value="" disabled>Fornitore</option>
                          <option
                            v-for="(supplier, index) in suppliers"
                            :key="index"
                          >
                            {{ supplier.name }}
                          </option>
                        </select>
                      </v-col>
                      <v-col class="nopd center_y" sm="12" v-else>
                        <div style="font-size: 16px" class="tw-mb-4">
                          <b>Fornitore: </b>{{ quotation.supplier }}
                        </div>
                      </v-col>

                      <v-col
                        class="nopd center_y"
                        sm="12"
                        v-if="!quotation.is_saved"
                      >
                        <div
                          style="width: 100%"
                          v-if="quotation.qty && quotation.qty.length > 0"
                        >
                          <div
                            style="display: flex; justify-content: center"
                            v-for="(block_qty, index2) in quotation.qty"
                            :key="index2"
                          >
                            <input
                              v-model="block_qty.qty"
                              style="width: 25% !important"
                              type="number"
                              class="input-block mg-5"
                              placeholder="Quantità"
                            />
                            <input
                              v-model="block_qty.price"
                              style="width: 25% !important"
                              type="number"
                              class="input-block mg-5"
                              placeholder="Prezzo"
                            />
                            <select
                              style="width: 25% !important"
                              v-model="block_qty.resa"
                              class="input-block mg-5"
                            >
                              <option value="" disabled>Resa</option>
                              <option value="Casemaker">Casemaker</option>
                              <option v-for="i in 15" :key="i">{{ i }}</option>
                            </select>
                            <input
                              v-model="block_qty.compo"
                              style="width: 25% !important"
                              type="text"
                              class="input-block mg-5"
                              placeholder="Compo"
                            />
                            <v-icon @click="deleteqtyQuotation(index)"
                              >mdi-minus-circle</v-icon
                            >
                          </div>
                          <div
                            style="
                              width: 100% !important;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                          >
                            <v-icon @click="addqtyQuotation(index)"
                              >mdi-plus-circle</v-icon
                            >
                          </div>
                        </div>
                      </v-col>
                      <v-col class="nopd center_y" sm="12" v-else>
                        <div
                          class="tw-w-full"
                          v-if="quotation.qty && quotation.qty.length > 0"
                        >
                          <div
                            class="tw-flex tw-justify-center tw-p-2 tw-bg-white tw-mb-2 tw-rounded-xl"
                            v-for="(block_qty, index2) in quotation.qty"
                            :key="index2"
                          >
                            <span class="tw-w-1/4"
                              ><b>Quantità: </b> {{ block_qty.qty }}</span
                            >
                            <span class="tw-w-1/4"
                              ><b>Prezzo: </b>€ {{ block_qty.price }}</span
                            >
                            <span class="tw-w-1/4"
                              ><b>Resa: </b> {{ block_qty.resa }}</span
                            >
                            <span class="tw-w-1/4"
                              ><b>Compo: </b> {{ block_qty.compo }}</span
                            >
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        class="nopd center_y"
                        sm="12"
                        v-if="!quotation.is_saved"
                      >
                        <input
                          multiple
                          type="file"
                          @change="quotationFiles"
                          class="input-block mg-5"
                          placeholder="Note"
                        />
                      </v-col>
                      <v-col class="nopd center_y" sm="12" v-else>
                        <div
                          v-if="quotation.files && quotation.files.length > 0"
                          class="box-f"
                        >
                          <div class="tw-my-2">Files</div>
                          <box-file
                            v-for="(file, index) in quotation.files"
                            :id_project="project.id"
                            :file="file"
                            :key="index"
                          ></box-file>
                        </div>
                      </v-col>
                      <v-col
                        class="nopd center_y"
                        sm="12"
                        v-if="!quotation.is_saved"
                      >
                        <textarea
                          v-model="quotation.notes"
                          class="input-block mg-5"
                          placeholder="Note"
                        ></textarea>
                      </v-col>
                      <v-col cols="12" class="nopd center_y" v-else>
                        <span
                          v-if="quotation.notes"
                          style="font-size: 16px; width: 50%"
                          ><b>Note <br /> </b>{{ quotation.notes }}</span
                        >
                      </v-col>
                      <v-col class="nopd center_y mg-10" sm="12">
                        <v-col class="nopd center">
                          <v-icon
                            v-if="!quotation.is_saved"
                            size="30"
                            class="action-file"
                            @click="saveQuotation(index, 'preventivi_scatola')"
                          >
                            mdi-check
                          </v-icon>
                          <v-icon
                            size="30"
                            class="action-file !tw-text-custom-red"
                            @click="
                              deleteQuotation(index, 'preventivi_scatola')
                            "
                          >
                            mdi-delete
                          </v-icon>
                        </v-col>
                      </v-col>
                      <v-col
                        v-if="quotation.creation"
                        class="nopd d-flex justify-end"
                        cols="12"
                      >
                        <p style="font-size: 12px">
                          <b>Data di creazione: </b>{{ quotation.creation }}
                        </p>
                      </v-col>
                      <v-col class="nopd center_y" sm="6">
                        <v-col class="nopd center"> </v-col>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-7'">
            <v-card
              flat
              class="tw-h-full !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border tw-p-6"
            >
              <v-row class="time-content" v-if="user.role == 'master'">
                <v-row>
                  <v-col cols="12">
                    <v-card-title class="time-title"
                      >Registra tempo di lavorazione</v-card-title
                    >
                  </v-col>
                  <v-col v-if="!start_time_tracciato && !isLocked" sm="6">
                    <button
                      class="v-btn theme--light elevation-2 v-size--default"
                      data-field="tracciato"
                      data-id="start"
                      @click="Task('start')"
                    >
                      Inizia</button
                    ><span>{{ start_time_tracciato }}</span>
                  </v-col>
                  <v-col
                    v-else-if="
                      start_time_tracciato && end_time_tracciato && !isLocked
                    "
                    sm="6"
                  >
                    <button
                      class="v-btn theme--light elevation-2 v-size--default"
                      data-field="tracciato"
                      data-id="start"
                      @click="Task('start')"
                    >
                      Riprendi</button
                    ><span>{{ start_time_tracciato }}</span>
                  </v-col>
                  <v-col v-else sm="6">
                    <button
                      disabled
                      class="v-btn theme--light elevation-2 v-size--default"
                      style="cursor: not-allowed; opacity: 0.7"
                      data-field="tracciato"
                      data-id="start"
                    >
                      Riprendi</button
                    ><span>{{ start_time_tracciato }}</span>
                  </v-col>

                  <v-col v-if="!end_time_tracciato && !isLocked" sm="6">
                    <button
                      ref="stop"
                      class="v-btn theme--light elevation-2 v-size--default"
                      data-field="tracciato"
                      data-id="end"
                      @click="Task('end')"
                    >
                      Stop</button
                    ><span>{{ end_time_tracciato }}</span>
                  </v-col>
                  <v-col v-else sm="6">
                    <button
                      disabled
                      class="v-btn theme--light elevation-2 v-size--default"
                      data-field="tracciato"
                      style="cursor: not-allowed; opacity: 0.7"
                      data-id="end"
                    >
                      Stop</button
                    ><span>{{ end_time_tracciato }}</span>
                  </v-col>
                </v-row>
              </v-row>
              <v-divider></v-divider>

              <v-row class="time-content tw-flex-1 tw-overflow-y-auto tw-h-3/4">
                <v-row class="wh" width="100%">
                  <v-col cols="12">
                    <v-card>
                      <v-card-title class="headline grey lighten-2">
                        Tracciato
                      </v-card-title>

                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Inizio</th>
                              <th class="text-left">Fine</th>
                              <th class="text-left">Tempo di lavoro</th>
                              <th class="text-left">Cancellare?</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in project.tracciato"
                              :key="index"
                            >
                              <td>
                                <TimeRow
                                  v-bind:time="item.start_time"
                                ></TimeRow>
                              </td>
                              <td>
                                <TimeRow v-bind:time="item.end_time"></TimeRow>
                              </td>
                              <td>
                                <TimeRow
                                  v-bind:time="item.final_time"
                                ></TimeRow>
                              </td>
                              <td style="text-align: center">
                                <v-icon
                                  class="pointer"
                                  @click="deleteTime(index, 'tracciato')"
                                  >mdi-close-circle</v-icon
                                >
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <PlusTime
                        v-bind:project_id="project.id"
                        v-bind:task_type="'tracciato'"
                      ></PlusTime>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card>
                      <v-card-title class="headline grey lighten-2">
                        Mastrino
                      </v-card-title>

                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Inizio</th>
                              <th class="text-left">Fine</th>
                              <th class="text-left">Tempo di lavoro</th>
                              <th class="text-left">Cancellare?</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in project.mastrino"
                              :key="index"
                            >
                              <td>
                                <TimeRow
                                  v-bind:time="item.start_time"
                                ></TimeRow>
                              </td>
                              <td>
                                <TimeRow v-bind:time="item.end_time"></TimeRow>
                              </td>
                              <td>
                                <TimeRow
                                  v-bind:time="item.final_time"
                                ></TimeRow>
                              </td>
                              <td style="text-align: center">
                                <v-icon
                                  class="pointer"
                                  @click="deleteTime(index, 'mastrino')"
                                  >mdi-close-circle</v-icon
                                >
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <PlusTime
                        v-bind:project_id="project.id"
                        v-bind:task_type="'mastrino'"
                      ></PlusTime>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card v-if="parseInt(result_time.split(':')[0])">
                      <v-card-title
                        v-if="parseInt(result_time.split(':')[0]) == 0"
                        class="headline grey lighten-2"
                      >
                        Tempo di lavoro totale:
                        {{ result_time.split(":")[1] }} minuti
                      </v-card-title>
                      <v-card-title v-else class="headline grey lighten-2">
                        Tempo di lavoro totale:
                        {{ result_time.split(":")[0] }} ore e
                        {{ result_time.split(":")[1] }} minuti
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-8'">
            <v-card
              flat
              class="tw-h-full !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border tw-p-6"
            >
              <v-row class="time-content">
                <v-col
                  cols="12"
                  class="tw-p-0 tw-mb-8 tw-flex tw-flex-col"
                  v-if="project.to_production != 'no'"
                >
                  <div class="tw-flex tw-items-center tw-mb-8">
                    <div class="tw-font-semibold tw-text-2xl">Produzioni</div>
                    <primary-btn
                      class="tw-ml-4 tw-mr-2"
                      text="Nuova/modifica produzione"
                      @click.native="openProductionDialog"
                    ></primary-btn>
                    <secondary-btn
                      text="Visualizza produzione"
                      @click.native="
                        $router
                          .push('/to-production/' + project._id)
                          .catch(() => {})
                      "
                    ></secondary-btn>
                  </div>
                  <div>
                    <div
                      v-if="
                        project.productions &&
                        project.production_status != 'completato'
                      "
                      class="tw-flex tw-w-full tw-flex tw-flex-col tw-p-4 tw-box-border"
                    >
                      <div class="tw-flex">
                        <div class="tw-font-bold tw-text-lg tw-mb-4 font2">
                          Produzione corrente
                        </div>
                      </div>

                      <div
                        class="tw-flex tw-mb-2 tw-box-border font2 tw-font-semibold tw-items-center tw-p-4"
                      >
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>Data di consegna</div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>Materiale</div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>Stato</div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>Ultima lavorazione</div>
                        </div>
                        <div class="tw-w-1/6 tw-text-center tw-align-middle">
                          <div>Durata lavorazione</div>
                        </div>
                      </div>
                      <div
                        class="tw-flex tw-mb-4 tw-bg-grey tw-rounded-xl tw-box-border tw-p-4 font2 tw-items-center tw-text-sm"
                      >
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>{{ project.productions.delivery_date }}</div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>{{ material.onda }}</div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>
                            <v-chip
                              dark
                              :color="
                                getProductionColor(project.productions.status)
                              "
                              >{{ project.productions.status }}</v-chip
                            >
                          </div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>Ultima lavorazione (in arrivo) n/d</div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>Durata lavorazione (in arrivo) n/d</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="
                      project.productions &&
                      project.productions.produzione.length > 0 &&
                      project.productions_status != 'completato'
                    "
                    class="tw-w-full tw-max-h-full tw-overflow-y-auto tw-flex tw-flex-col tw-p-4 tw-box-border"
                  >
                    <div class="tw-font-bold tw-text-lg tw-mt-8 tw-mb-4 font2">
                      Storico produzioni
                    </div>
                    <div
                      class="tw-flex tw-mb-2 tw-box-border font2 tw-font-semibold tw-items-center tw-p-4"
                    >
                      <div class="tw-w-1/5 tw-text-center tw-align-middle">
                        <div>Data di consegna</div>
                      </div>
                      <div class="tw-w-1/5 tw-text-center tw-align-middle">
                        <div>Materiale</div>
                      </div>
                      <div class="tw-w-1/5 tw-text-center tw-align-middle">
                        <div>Stato</div>
                      </div>
                      <div class="tw-w-1/5 tw-text-center tw-align-middle">
                        <div>Ultima lavorazione</div>
                      </div>
                      <div class="tw-w-1/5 tw-text-center tw-align-middle">
                        <div>Durata lavorazione</div>
                      </div>
                    </div>
                    <div
                      v-for="(
                        production, index
                      ) in project.productions.produzione.slice(0, -1)"
                      :key="index"
                    >
                      <div
                        v-if="production && production.length > 0"
                        class="tw-flex tw-mb-4 tw-bg-grey tw-rounded-xl tw-box-border tw-p-4 font2 tw-text-sm tw-items-center"
                      >
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>{{ production[0].delivery_date }}</div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div v-if="production[0] && production[0].data">
                            {{ production[0].data.material.onda }}
                          </div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>
                            <v-chip
                              dark
                              request_date
                              :color="getProductionColor('completato')"
                              >completato</v-chip
                            >
                          </div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>Ultima lavorazione (in arrivo)</div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>Durata lavorazione (in arrivo)</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      project.productions &&
                      project.productions.produzione.length > 0 &&
                      project.productions.status == 'completato'
                    "
                    class="tw-w-full l tw-max-h-full tw-overflow-y-auto tw-flex tw-flex-col tw-p-4 tw-box-border"
                  >
                    <div class="tw-font-bold tw-text-lg tw-mt-8 tw-mb-4 font2">
                      Storico produzioni
                    </div>
                    <div
                      class="tw-flex tw-mb-2 tw-box-border font2 tw-font-semibold tw-items-center tw-p-4"
                    >
                      <div class="tw-w-1/5 tw-text-center tw-align-middle">
                        <div>Data di consegna</div>
                      </div>
                      <div class="tw-w-1/5 tw-text-center tw-align-middle">
                        <div>Materiale</div>
                      </div>
                      <div class="tw-w-1/5 tw-text-center tw-align-middle">
                        <div>Stato</div>
                      </div>
                      <div class="tw-w-1/5 tw-text-center tw-align-middle">
                        <div>Ultima lavorazione</div>
                      </div>
                      <div class="tw-w-1/5 tw-text-center tw-align-middle">
                        <div>Durata lavorazione</div>
                      </div>
                    </div>
                    <div
                      v-for="(production, index) in project.productions
                        .produzione"
                      :key="index"
                    >
                      <div
                        v-if="production && production.length > 0"
                        class="tw-flex tw-mb-4 tw-bg-grey tw-rounded-xl tw-box-border tw-p-4 font2 tw-text-sm tw-items-center"
                      >
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>{{ production[0].delivery_date }}</div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div v-if="production[0] && production[0].data">
                            {{ production[0].data.material.onda }}
                          </div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>
                            <v-chip
                              dark
                              request_date
                              :color="getProductionColor('completato')"
                              >completato</v-chip
                            >
                          </div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>Ultima lavorazione (in arrivo)</div>
                        </div>
                        <div class="tw-w-1/5 tw-text-center tw-align-middle">
                          <div>Durata lavorazione (in arrivo)</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="tw-p-0 tw-mb-8" v-else>
                  <div class="tw-flex tw-flex-col">
                    <div
                      v-if="user.role == 'commerciale'"
                      class="tw-font-semibold tw-text-2xl"
                    >
                      Nessuna produzione. Crearne una?
                    </div>
                    <div v-else class="tw-font-semibold tw-text-2xl">
                      Nessuna produzione.
                    </div>
                    <primary-btn
                      v-if="user.role == 'commerciale'"
                      class="tw-mt-4"
                      @click.native="openProductionDialog"
                      text="Crea produzione"
                    ></primary-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-9'">
            <v-card
              flat
              class="tw-h-full !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border tw-p-6"
            >
              <v-row class="tw-p-0 tw-bg-white !tw-h-full">
                <v-col cols="12" class="tw-p-2 tw-flex tw-flex-col">
                  <div class="tw-flex tw-items-center">
                    <div class="tw-font-semibold tw-text-2xl">
                      Calcolo BCT/ECT
                    </div>
                    <primary-btn
                      @click.native="
                        $store.state.bctModal = !$store.state.bctModal
                      "
                      text="Nuovo Calcolo"
                      class="tw-ml-2"
                    ></primary-btn>
                  </div>
                  <div
                    v-if="project.bct && project.bct.length > 0"
                    class="!tw-flex-1 tw-w-full md:tw-w-2/3 tw-overflow-y-auto tw-mt-8"
                  >
                    <BctListItem
                      v-for="(item, index) in project.bct"
                      :item="item"
                      :index="index"
                      :key="index"
                      :project_id="project.id"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-10'">
            <v-card
              flat
              class="tw-h-full !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border tw-p-6"
            >
              <v-row class="tw-p-0 tw-bg-white !tw-h-full">
                <v-col
                  cols="12"
                  class="tw-p-2 tw-flex tw-flex-col tw-h-[inherit]"
                >
                  <div class="tw-flex tw-items-center">
                    <div class="tw-font-semibold tw-text-2xl">
                      Progetti Realtà Aumentata
                    </div>
                    <primary-btn
                      @click.native="
                        $store.state.arProjectModal =
                          !$store.state.arProjectModal
                      "
                      text="Nuovo Progetto"
                      class="tw-ml-2"
                    ></primary-btn>
                  </div>
                  <div class="tw-h-[inherit] tw-overflow-y-hidden mt-4">
                    <div
                      v-if="
                        project.ar_projects && project.ar_projects.length > 0
                      "
                      class="!tw-flex-1 tw-h-[inherit] tw-flex-row tw-flex tw-flex-wrap tw-w-full tw-overflow-y-auto"
                    >
                      <div
                        v-for="(item, index) in project.ar_projects"
                        :key="index"
                        class="tw-flex tw-w-1/3 tw-box-border tw-p-2"
                      >
                        <ArCard
                          :project_id="project.id"
                          :item="item"
                          :index="index"
                        />
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-11'">
            <v-card
              flat
              class="tw-h-full !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border tw-p-6"
            >
              <v-row class="tw-p-0 tw-bg-white !tw-h-full">
                <v-col
                  cols="12"
                  class="tw-p-2 tw-flex tw-flex-row tw-h-[inherit]"
                >
                  <div class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col">
                    <div class="tw-mb-8 tw-flex tw-items-center">
                      <div class="tw-flex">
                        <div class="tw-font-semibold md:tw-text-2xl tw-text-lg">
                          Preventivi Fustella<span
                            v-if="
                              project.quotations.filter(
                                (item) => item.type == 'preventivi_fustella'
                              ) &&
                              project.quotations.filter(
                                (item) => item.type == 'preventivi_fustella'
                              ).length > 0
                            "
                            >({{
                              project.quotations.filter(
                                (item) => item.type == "preventivi_fustella"
                              ).length
                            }})</span
                          >
                        </div>
                        <CreationModal
                          :machineSuppliers="suppliers"
                          type="preventivi_fustella"
                          :project="project"
                          :isNew="true"
                          @quotationCreated="handleQuotationCreated"
                        />
                      </div>
                    </div>
                    <div
                      class="tw-flex-1 tw-h-full tw-w-full md:tw-overflow-y-hidden tw-overflow-y-scroll"
                    >
                      <div
                        class="md:tw-ml-8"
                        v-if="
                          project.quotations.filter(
                            (item) => item.type == 'preventivi_fustella'
                          ) &&
                          project.quotations.filter(
                            (item) => item.type == 'preventivi_fustella'
                          ).length === 0
                        "
                      >
                        <p>Non ci sono preventivi Impianti fustella...</p>
                      </div>
                      <div
                        v-if="
                          project.quotations.filter(
                            (item) => item.type == 'preventivi_fustella'
                          ) &&
                          project.quotations.filter(
                            (item) => item.type == 'preventivi_fustella'
                          ).length > 0
                        "
                        class="tw-flex tw-flex-col tw-max-h-full md:tw-overflow-y-auto tw-overflow-y-scroll md:tw-pl-8 tw-pl-4"
                      >
                        <QuotationItem
                          :isActionEnabled="true"
                          quotationType="preventivi_fustella"
                          @deleted-quotation="handleQuotationDelete"
                          v-for="(
                            quotation, index
                          ) in project.quotations.filter(
                            (item) => item.type == 'preventivi_fustella'
                          )"
                          :key="index"
                          :index="index"
                          :quotation="quotation"
                          :_id="project._id"
                          :ica_files="
                            project.ica_files.filter(
                              (f) => f.type == 'scheda_tecnica' && !f.archived
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col">
                    <div class="tw-mb-8 tw-flex tw-items-center">
                      <div class="tw-flex">
                        <div class="tw-font-semibold md:tw-text-2xl tw-text-lg">
                          Preventivi Impianti Stampa<span
                            v-if="
                              project.quotations.filter(
                                (item) => item.type == 'preventivi_stampa'
                              ) &&
                              project.quotations.filter(
                                (item) => item.type == 'preventivi_stampa'
                              ).length > 0
                            "
                            >({{
                              project.quotations.filter(
                                (item) => item.type == "preventivi_stampa"
                              ).length
                            }})</span
                          >
                        </div>
                        <CreationModal
                          :machineSuppliers="suppliers"
                          type="preventivi_stampa"
                          :project="project"
                          :isNew="true"
                          @quotationCreated="handleQuotationCreated"
                        />
                      </div>
                    </div>
                    <div
                      class="tw-flex-1 tw-h-full tw-w-full md:tw-overflow-y-hidden tw-overflow-y-scroll"
                    >
                      <div
                        class="md:tw-ml-8"
                        v-if="
                          project.quotations.filter(
                            (item) => item.type == 'preventivi_stampa'
                          ) &&
                          project.quotations.filter(
                            (item) => item.type == 'preventivi_stampa'
                          ).length === 0
                        "
                      >
                        <p>Non ci sono preventivi Impianti stampa...</p>
                      </div>
                      <div
                        v-if="
                          project.quotations.filter(
                            (item) => item.type == 'preventivi_stampa'
                          ) &&
                          project.quotations.filter(
                            (item) => item.type == 'preventivi_stampa'
                          ).length > 0
                        "
                        class="tw-flex tw-flex-col tw-max-h-full md:tw-overflow-y-auto tw-overflow-y-scroll md:tw-pl-8 tw-pl-4"
                      >
                        <QuotationItem
                          :isActionEnabled="true"
                          quotationType="preventivi_stampa"
                          @deleted-quotation="handleQuotationDelete"
                          v-for="(
                            quotation, index
                          ) in project.quotations.filter(
                            (item) => item.type == 'preventivi_stampa'
                          )"
                          :key="index"
                          :index="index"
                          :quotation="quotation"
                          :_id="project._id"
                          :ica_files="
                            project.ica_files.filter(
                              (f) => f.type == 'mastrino' && !f.archived
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>

                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <BctModal :id_project="project.id" />
      <v-dialog
        v-model="new_deposite_dialog"
        content-class="dl-time"
        scrollable
        max-height="600px"
        width="500px"
      >
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Nuovo Pagamento
          </v-card-title>
          <v-row class="time-content">
            <v-col cols="12">
              <v-text-field
                label="Riferimento pagamento"
                outlined
                v-model="new_payment.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Richiesta di pagamento di €"
                outlined
                type="number"
                v-model="new_payment.request.qty"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                label="Da scalare dall'ordine"
                outlined
                type="number"
                false-value="0"
                true-value="1"
                v-model="new_payment.request.is_scalated"
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Totale da scalare €"
                outlined
                type="number"
                false-value="0"
                true-value="1"
                v-if="new_payment.request.is_scalated == 1"
                v-model="new_payment.request.to_scale"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                label="Calcolare IVA"
                outlined
                type="number"
                false-value="0"
                true-value="1"
                v-model="new_payment.request.has_iva"
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                label="Vuoi monitorare il pagamento?"
                outlined
                type="number"
                false-value="0"
                true-value="1"
                v-model="new_payment.is_monitored"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="new_deposite_dialog = false"
            >
              Annulla
            </v-btn>
            <v-btn color="blue darken-1" text @click="newPayment"> Crea </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="new_prev_dialog"
        content-class="dl-time"
        scrollable
        max-height="600px"
        width="800px"
      >
        <v-card>
          <!-- RENDERIZZA FUSTELLA -->

          <form
            v-if="selected_prev_type == 'preventivi_fustella'"
            class="tw-full tw-flex tw-flex-wrap tw-box-border tw-p-8 font2 quotation-form"
          >
            <div class="tw-text-xl tw-font-bold tw-mb-8">
              Nuovo Preventivo Fustella
            </div>
            <div class="tw-flex tw-items-center tw-w-full tw-mb-2">
              <div>Fornitore</div>
              <div class="tw-flex-1 tw-ml-4">
                <v-select
                  placeholder="Seleziona il fornitore macchina"
                  :items="filterSuppliers(suppliers, 'fustella')"
                  @change="generateSuppliers(suppliers, 'fustella')"
                  :disabled="isDisabled"
                  filled
                  item-text="name"
                  v-model="fustella_model.machine_supplier"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!fustella_model.machine_supplier"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
              </div>
            </div>
            <div v-if="fustella_model.suppliers.length > 0" class="tw-w-full">
              <div class="tw-flex tw-flex-col">
                <div class="tw-text-lg tw-font-bold tw-mb-2 tw-mt-2">
                  Verranno generati i seguenti preventivi:
                </div>
                <div
                  class="tw-box-border tw-bg-azure tw-rounded-xl tw-px-4 tw-py-2 tw-mt-2 tw-flex tw-text-sm"
                  v-for="(block, index) in fustella_model.suppliers"
                  :key="index"
                >
                  <div><b>Fornitore: </b> {{ block.supplier }}</div>
                  <div class="tw-ml-4"><b>Prezzo: </b> In attesa</div>
                </div>
              </div>
            </div>
            <div class="tw-flex tw-items-center md:tw-w-1/2 tw-w-full tw-mt-4">
              <div>Macchina</div>
              <div class="tw-flex-1 tw-ml-4">
                <v-select
                  placeholder="Seleziona il tipo di macchina"
                  :items="['Piana', 'Rotativa', 'Casemaker', 'Platina']"
                  :disabled="isDisabled"
                  :required="true"
                  filled
                  v-model="fustella_model.machine_type"
                >
                </v-select>
              </div>
            </div>
            <div class="tw-flex tw-items-center md:tw-w-1/2 tw-w-full tw-mt-4">
              <div class="tw-ml-4">Resa</div>
              <div class="tw-ml-4 tw-flex-1">
                <v-select
                  placeholder="Seleziona la resa"
                  :clearable="true"
                  filled
                  :items="[
                    'Casemaker',
                    1,
                    2,
                    3,
                    4,
                    5,
                    6,
                    7,
                    8,
                    9,
                    10,
                    11,
                    12,
                    13,
                    14,
                    15,
                    16,
                    17,
                    18,
                    19,
                    20,
                    21,
                    22,
                    23,
                    24,
                    25,
                  ]"
                  :disabled="isDisabled"
                  required
                  v-model="fustella_model.resa"
                >
                </v-select>
              </div>
            </div>
            <div class="tw-flex tw-w-full tw-mt-2">
              <div>Note</div>
              <div class="tw-flex-1 tw-ml-4">
                <textarea
                  filled
                  class="tw-bg-grey tw-rounded-xl tw-w-full tw-h-64 tw-box-border tw-p-4"
                  v-model="fustella_model.referrer_notes"
                  placeholder="Inserisci le note..."
                ></textarea>
              </div>
            </div>
          </form>

          <!-- RENDERIZZA IMPIANTO STAMPA -->

          <form
            v-else-if="selected_prev_type == 'preventivi_stampa'"
            class="tw-full tw-flex tw-flex-wrap tw-box-border tw-p-8 font2 quotation-form"
          >
            <div class="tw-flex tw-w-full tw-items-center tw-mb-8">
              <div class="tw-text-xl tw-font-bold">
                Nuovo Preventivo Impianto Stampa
              </div>
              <div class="tw-flex-1" v-if="isNew">
                <v-radio-group
                  row
                  v-model="quotationRequestType"
                  class="!tw-mt-6 !tw-mb-0 !tw-pt-0 !tw-ml-2"
                >
                  <v-radio label="Preventivo" value="quotation"></v-radio>
                  <v-radio label="Ordine" value="order"></v-radio>
                </v-radio-group>
              </div>
            </div>
            <div class="tw-flex tw-items-center tw-w-full tw-mb-2">
              <div>Stampatore</div>
              <div class="tw-flex-1 tw-ml-4">
                <v-select
                  filled
                  placeholder="Seleziona lo stampatore"
                  label="Seleziona il fornitore"
                  :items="filterSuppliers(suppliers, 'stampa')"
                  @change="generateSuppliers(suppliers, 'stampa')"
                  :disabled="isDisabled"
                  required
                  item-text="name"
                  v-model="stampa_model.machine_supplier"
                />
              </div>
            </div>
            <div v-if="stampa_model.suppliers.length > 0" class="tw-w-full">
              <div class="tw-flex tw-flex-col">
                <div class="tw-text-lg tw-font-bold tw-mb-2 tw-mt-2">
                  Verranno generati i seguenti preventivi:
                </div>
                <div
                  class="tw-box-border tw-bg-azure tw-rounded-xl tw-px-4 tw-py-2 tw-mt-2 tw-flex tw-text-sm"
                  v-for="(block, index) in stampa_model.suppliers"
                  :key="index"
                >
                  <div><b>Fornitore: </b> {{ block.supplier }}</div>
                  <div class="tw-ml-4"><b>Prezzo: </b> In attesa</div>
                </div>
              </div>
            </div>
            <div class="tw-flex tw-items-center md:tw-w-1/2 tw-w-full tw-mt-4">
              <div>Materiale</div>
              <div class="tw-flex-1 tw-ml-4">
                <v-select
                  placeholder="Seleziona il Materiale"
                  label="Seleziona il materiale"
                  :items="['Solido Analogico', 'Solido Digitale', 'Liquido']"
                  :disabled="isDisabled"
                  required
                  v-model="stampa_model.tp"
                  filled
                >
                </v-select>
              </div>
            </div>
            <div
              class="tw-flex tw-items-center md:tw-w-1/2 tw-w-full tw-justify-end tw-mt-4"
            >
              <div class="tw-ml-4">Resa</div>
              <div class="tw-ml-4 tw-flex-1">
                <v-select
                  placeholder="Seleziona la resa"
                  label="Seleziona la resa"
                  :items="[
                    'Casemaker',
                    1,
                    2,
                    3,
                    4,
                    5,
                    6,
                    7,
                    8,
                    9,
                    10,
                    11,
                    12,
                    13,
                    14,
                    15,
                    16,
                    17,
                    18,
                    19,
                    20,
                    21,
                    22,
                    23,
                    24,
                    25,
                  ]"
                  :disabled="isDisabled"
                  required
                  filled
                  v-model="stampa_model.resa"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!stampa_model.resa"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
              </div>
            </div>
            <div v-if="isNew" class="tw-flex tw-w-full tw-flex-wrap">
              <div
                class="tw-flex tw-items-center md:tw-w-1/2 tw-w-full tw-justify-end tw-mt-4"
              >
                <div>Codice Commessa</div>
                <div class="tw-ml-4 tw-flex-1">
                  <v-text-field
                    placeholder="Inserisci Codice commessa"
                    label="Codice commessa"
                    filled
                    v-model="stampa_model.rif_code"
                  ></v-text-field>
                </div>
              </div>
              <div
                class="tw-flex tw-items-center md:tw-w-1/2 tw-w-full tw-justify-end tw-mt-4"
                v-if="quotationRequestType == 'order'"
              >
                <div class="tw-pl-4">Prezzo</div>
                <div class="tw-ml-4 tw-flex-1">
                  <v-text-field
                    placeholder="Inserisci Prezzo"
                    label="Prezzo"
                    filled
                    type="number"
                    v-model="stampa_model.price"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </form>

          <!-- RENDERIZZA SCATOLA -->

          <form
            v-else-if="selected_prev_type == 'preventivi_scatola'"
            class="tw-full tw-flex tw-flex-wrap tw-box-border tw-p-8 font2 quotation-form"
          >
            <div class="tw-text-xl tw-font-bold tw-mb-8">
              Nuovo Preventivo Scatola
            </div>
            <div class="tw-flex tw-items-center tw-w-full tw-mb-2">
              <div>Fornitore</div>
              <div class="tw-flex-1 tw-ml-4">
                <custom-select
                  placeholder="Seleziona il fornitore"
                  :clearable="true"
                  label="name"
                  :options="suppliers"
                  :disabled="isDisabled"
                  required
                  class="!tw-bg-grey tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                  v-model="scatola_model.supplier"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!scatola_model.supplier"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </custom-select>
              </div>
            </div>
            <div class="tw-flex tw-items-center tw-w-full tw-mt-4">
              <div class="tw-w-full">
                <div
                  class="tw-flex tw-w-full tw-justify-center tw-mb-2"
                  v-for="(block_qty, index2) in scatola_model.qty"
                  :key="index2"
                >
                  <div class="tw-w-1/4">
                    <input-field
                      :darker="true"
                      class="tw-w-full"
                      type="number"
                      v-model="block_qty.qty"
                      placeholder="Quantità"
                      :disabled="isDisabled"
                      required
                    ></input-field>
                  </div>
                  <div class="tw-w-1/4 tw-ml-2">
                    <input-field
                      step="any"
                      :darker="true"
                      class="tw-w-full"
                      type="number"
                      v-model="block_qty.price"
                      placeholder="Prezzo"
                      :disabled="isDisabled"
                      required
                    ></input-field>
                  </div>

                  <div class="tw-w-1/4 tw-mx-2">
                    <custom-select
                      placeholder="Resa"
                      label="name"
                      :options="[
                        'Casemaker',
                        1,
                        2,
                        3,
                        4,
                        5,
                        6,
                        7,
                        8,
                        9,
                        10,
                        11,
                        12,
                        13,
                        14,
                        15,
                      ]"
                      :disabled="isDisabled"
                      required
                      class="!tw-bg-grey tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                      v-model="block_qty.resa"
                    >
                    </custom-select>
                  </div>

                  <div class="tw-w-1/4">
                    <input-field
                      :darker="true"
                      class="tw-w-full"
                      type="text"
                      v-model="block_qty.compo"
                      placeholder="Compo"
                      :disabled="isDisabled"
                      required
                    ></input-field>
                  </div>
                  <v-icon
                    v-if="scatola_model.qty.length > 1"
                    class="tw-ml-2"
                    @click="deleteqtyQuotation(index2)"
                    >mdi-minus-circle</v-icon
                  >
                </div>
                <div class="tw-flex tw-mt-2 tw-justify-center tw-items-center">
                  <v-icon @click="addqtyQuotation()">mdi-plus-circle</v-icon>
                </div>
              </div>
            </div>
            <div
              class="tw-flex tw-items-center tw-w-full tw-justify-end tw-mt-4"
            >
              <div>File</div>
              <div class="tw-ml-4 tw-flex-1">
                <input-field
                  :multiple="true"
                  :darker="true"
                  class="tw-w-full"
                  type="file"
                  placeholder="Files"
                  :disabled="isDisabled"
                  @input.native="quotationFiles"
                ></input-field>
              </div>
            </div>
            <div class="tw-flex tw-w-full tw-mt-2">
              <div>Note</div>
              <div class="tw-flex-1 tw-ml-4">
                <textarea
                  class="tw-bg-grey tw-rounded-xl tw-w-full tw-h-64 tw-box-border tw-p-4"
                  v-model="scatola_model.referrer_notes"
                  placeholder="Inserisci le note..."
                ></textarea>
              </div>
            </div>
          </form>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="
                new_prev_dialog = false;
                isNew = false;
              "
            >
              Annulla
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isLoading.quotation.creating"
              @click="createQuotation(selected_prev_type)"
            >
              Crea
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div
        v-if="isMobileChatVisible"
        class="timeline tw-z-50 tw-flex-1 md:tw-w-[25%] !tw-bg-white !tw-h-full md:tw-ml-4 box-border tw-rounded-xl tw-flex tw-flex-col md:tw-relative tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 md:tw-top-auto md:tw-left-auto md:tw-transform-none md:tw--translate-x-0 md:tw--translate-y-0 tw-w-full md:tw-overflow-y-hidden tw-overflow-y-scroll"
      >
        <div class="tw-flex md:tw-p-8 tw-p-4 tw-items-center">
          <div class="tw-text-2xl tw-flex-1 tw-font-semibold">Timeline</div>
          <div
            @click="isMobileChatVisible = false"
            class="tw-bg-custom-blue tw-w-10 tw-h-10 tw-flex md:tw-hidden tw-items-center tw-justify-center tw-rounded-full"
          >
            <v-icon class="!tw-text-white">mdi-close</v-icon>
          </div>
        </div>
        <div
          class="md:tw-flex-1 md:tw-h-full tw-h-[60%] tw-overflow-hidden tw-p-6"
        >
          <div
            class="tw-flex tw-flex-col tw-h-full tw-overflow-y-auto font2"
            ref="messagesContainer"
          >
            <div
              v-for="(block, date) in project.talk.length > 0
                ? sortedTalkProject
                : project.talk"
              :key="date"
              class="tw-w-full tw-flex tw-flex-col"
            >
              <div
                class="tw-text-center tw-text-xl tw-font-medium tw-mb-4 tw-mt-2"
              >
                - {{ date }} -
              </div>
              <div
                v-for="(task, index) in block"
                :key="index"
                :class="taskStatus(task.is_read, task.sender).bg"
                class="tw-p-4 tw-rounded-xl tw-flex tw-flex-col tw-mb-2"
              >
                <div class="tw-flex tw-justify-end"></div>
                <div class="tw-font-bold tw-mb-2 tw-text-lg">
                  {{ task.sender }}
                </div>
                <div
                  class="tw-text-md tw-whitespace-pre-line"
                  v-html="task.msg"
                ></div>
                <div
                  class="tw-w-full tw-flex tw-justify-between tw-mt-8 tw-items-center"
                >
                  <div
                    v-if="!task.is_read"
                    class="tw-text-xs tw-text-custom-red tw-font-bold"
                  >
                    {{ taskStatus(task.is_read).text }}
                  </div>
                  <div v-else class="tw-text-xs tw-text-green tw-font-bold">
                    {{ taskStatus(task.is_read, task.sender).text }}
                  </div>
                  <div class="tw-text-xs tw-font-bold">{{ task.time }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="user.role == 'commerciale'"
          class="!tw-h-24 tw-box-border tw-p-4"
        >
          <div class="tw-w-full tw-flex tw-items-center tw-h-full tw-relative">
            <textarea
              id="send"
              :class="{ box_full_h: msgBox }"
              class="tw-bg-azure tw-p-4 tw-rounded-xl l tw-w-full tw-bottom-0 tw-absolute tw-h-full tw-transition-all"
              @click="toggleBox('opening')"
              @blur="toggleBox('closing')"
              :disabled="isDisabled"
              @keyup.enter="addLineBreak"
              v-model="current_msg"
              placeholder="Scrivi un messaggio..."
              type="text"
              name="message"
            ></textarea>
            <button
              v-if="current_msg != ''"
              @click="dialog_priority = true"
              class="tw-absolute tw-right-0 tw-w-12 tw-h-12 tw-p-4 tw-flex tw-items-center tw-justify-center tw-bg-custom-blue tw-rounded-xl tw-mr-2 hover:tw-opacity-80 tw-cursor-pointer"
            >
              <v-icon dark>mdi-send</v-icon>
            </button>
            <button
              v-else
              disabled
              class="tw-cursor-not-allowed tw-opacity-80 tw-absolute tw-right-0 tw-w-12 tw-h-12 tw-p-4 tw-flex tw-items-center tw-justify-center tw-bg-custom-blue tw-rounded-xl tw-mr-2 hover:tw-opacity-80r"
            >
              <v-icon dark>mdi-send</v-icon>
            </button>
          </div>
        </div>
      </div>
    </v-row>

    <response-notification></response-notification>
    <div
      v-if="!isMobileChatVisible"
      class="tw-absolute tw-bottom-[12%] tw-right-[8%] md:tw-hidden tw-block !tw-z-50"
    >
      <div
        @click="isMobileChatVisible = true"
        class="tw-bg-custom-blue tw-relative tw-w-16 tw-h-16 tw-flex md:tw-hidden tw-items-center tw-justify-center tw-rounded-full"
      >
        <v-icon class="!tw-text-white">mdi-chat</v-icon>
      </div>
    </div>
    <div id="loading">
      <div class="shape">
        <v-progress-circular
          :rotate="360"
          :size="200"
          :width="25"
          :value="uploading"
          color="#1f7a8c"
          class="loading"
        >
          {{ uploading }}
        </v-progress-circular>
      </div>
    </div>

    <v-alert
      v-if="project.isEditing && project.isEditing != current_user"
      elevation="7"
      type="warning"
      :value="true"
      class="isediting"
    >
      {{ project.isEditing }} sta lavorando al progetto.
    </v-alert>

    <v-dialog v-model="dialog_priority" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Seleziona la priorità</span>
        </v-card-title>
        <v-card-subtitle
          class="!tw-font-medium !tw-text-lg tw-flex tw-flex-col"
        >
          <div class="tw-flex tw-flex-col tw-mt-6">
            <div
              v-if="
                user.priority_limit &&
                !user.priority_limit.in_corso.allocated.includes(project._id)
              "
              class="tw-flex"
            >
              <v-icon class="tw-mr-2" dark color="red">mdi-alert-circle</v-icon>
              <div
                v-if="
                  user.priority_limit &&
                  user.priority_limit.in_corso.limit -
                    user.priority_limit.in_corso.allocated.length >
                    0
                "
              >
                {{
                  user.priority_limit.in_corso.limit -
                  user.priority_limit.in_corso.allocated.length
                }}
                priorità "ordine in corso" disponibili
              </div>
              <div v-else>
                Non hai più priorità "ordine in corso" disponibili
              </div>
            </div>
          </div>

          <div class="tw-flex tw-flex-col tw-mt-4 tw-mb-4">
            <div
              v-if="
                user.priority_limit &&
                !user.priority_limit.urgente.allocated.includes(project._id)
              "
              class="tw-flex"
            >
              <v-icon class="tw-mr-2" dark color="orange"
                >mdi-alert-circle</v-icon
              >
              <div
                v-if="
                  user.priority_limit &&
                  user.priority_limit.urgente.limit -
                    user.priority_limit.urgente.allocated.length >
                    0
                "
              >
                {{
                  user.priority_limit.urgente.limit -
                  user.priority_limit.urgente.allocated.length
                }}
                priorità "urgente" disponibili
              </div>
              <div v-else>Non hai più priorità "urgente" disponibili</div>
            </div>
          </div>
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                label="Seleziona la priorità*"
                required
                v-model="priorityStatus"
                :items="priorities"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_priority = false">
            Annulla
          </v-btn>
          <v-btn color="blue darken-1" text @click="newTask"> Salva </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_delegate" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline"
            ><b>Avviso:</b> Questo progetto è stato delegato a
            {{ project.delegate }}</span
          >
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_delegate = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ar-project-modal></ar-project-modal>

    <v-dialog v-model="alert" width="500">
      <v-card style="background-color: white">
        <v-card-title style="margin-bottom: 20px">
          <h1>Attenzione!</h1>
        </v-card-title>

        <v-card-subtitle
          ><h3>
            I seguenti file superano il limite di caricamento di 7 MB, per cui
            NON verranno caricati:
          </h3>
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col v-if="unuploaded_files.length > 0" cols="12" class="d-flex">
              <v-card
                style="width: 100% !important; color: black !important"
                v-for="(file, index) in unuploaded_files"
                :key="index"
                ><v-card-text style="width: 100% !important">{{
                  file
                }}</v-card-text></v-card
              >
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeFilesAlert">
            Ho capito
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="user.role == 'commerciale' && is_p_saved"
      v-model="dialog_production"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Dati Produzione</span>
        </v-card-title>
        <v-card-text>
          <form
            class="production-confirm tw-w-full tw-flex md:tw-flex-wrap tw-flex-col md:tw-flex-row"
          >
            <v-row>
              <v-col cols="12" md="2">
                <v-text-field
                  @input="checkEdit"
                  label="Quantità*"
                  required
                  v-model="pieces_prod"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  @input="checkEdit"
                  label="Onda*"
                  required
                  v-model="material.onda"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  @input="checkEdit"
                  label="Copertina Est.*"
                  required
                  outlined
                  v-model="material.copertina_est"
                  :items="['Bianca', 'Avana', 'Patinata', 'Indifferente']"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  @input="checkEdit"
                  label="Copertina Int.*"
                  required
                  v-model="material.copertina_int"
                  :items="['Bianca', 'Avana', 'Patinata', 'Indifferente']"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" class="nopd">
                <v-card-title>
                  <span class="headline">Data di consegna</span>
                </v-card-title>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  @input="checkEdit"
                  label="Inserisci una data*"
                  required
                  v-model="delivery_date"
                  type="date"
                  :min="today"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="nopd">
                <v-card-title>
                  <span class="headline">Palletizzazione</span>
                </v-card-title>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="palletizzazione"
                  :items="pallet"
                  label="Seleziona palletizzazione"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                  v-model="legato"
                  label="Legato a pacchi"
                  value="Legato a pacchi"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                  v-model="legato"
                  label="Film Estensibile"
                  value="Film Estensibile"
                ></v-checkbox>
              </v-col>
              <v-col v-if="palletizzazione == pallet[0]" cols="12" class="nopd">
                <v-card-title>
                  <span class="headline">Tipo di bancale</span>
                </v-card-title>
              </v-col>
              <v-col cols="12" v-if="palletizzazione == pallet[0]">
                <v-select
                  v-model="bancale"
                  :items="bancali"
                  label="Seleziona tipo di bancale"
                  outlined
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  @input="checkEdit"
                  label="Note"
                  outlined
                  v-model="notes_prod"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <div v-if="project.last_status != 'in attesa'">
          <v-card-title>
            <span class="headline">Seleziona la priorità</span>
          </v-card-title>
          <v-card-subtitle
            class="!tw-font-medium !tw-text-lg tw-flex tw-flex-col"
          >
            <div class="tw-flex tw-flex-col tw-mt-6">
              <div
                v-if="
                  user.priority_limit &&
                  !user.priority_limit.in_corso.allocated.includes(project.id)
                "
                class="tw-flex"
              >
                <v-icon class="tw-mr-2" dark color="red"
                  >mdi-alert-circle</v-icon
                >
                <div
                  v-if="
                    user.priority_limit &&
                    user.priority_limit.in_corso.limit > 0
                  "
                >
                  {{ user.priority_limit.in_corso.limit }} priorità "ordine in
                  corso" disponibili
                </div>
                <div v-else>
                  Non hai più priorità "ordine in corso" disponibili
                </div>
              </div>
            </div>

            <div class="tw-flex tw-flex-col tw-mt-4 tw-mb-4">
              <div
                v-if="
                  user.priority_limit &&
                  !user.priority_limit.urgente.allocated.includes(project.id)
                "
                class="tw-flex"
              >
                <v-icon class="tw-mr-2" dark color="orange"
                  >mdi-alert-circle</v-icon
                >
                <div
                  v-if="
                    user.priority_limit && user.priority_limit.urgente.limit > 0
                  "
                >
                  {{ user.priority_limit.urgente.limit }} priorità "urgente"
                  disponibili
                </div>
                <div v-else>Non hai più priorità "urgente" disponibili</div>
              </div>
            </div>
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-select
                  label="Seleziona la priorità*"
                  required
                  v-model="project.priority"
                  :items="priorities"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_production = false">
            Annulla
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirmProduction">
            Richiedi Produzione
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-else-if="user.role == 'master' && is_p_saved"
      v-model="dialog_production"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Dati Produzione</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                @input="checkEdit"
                label="Quantità*"
                required
                v-model="pieces_prod"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                @input="checkEdit"
                label="Onda*"
                required
                v-model="material.onda"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                @input="checkEdit"
                label="Copertina Est.*"
                required
                v-model="material.copertina_est"
                :items="['Bianca', 'Avana', 'Patinata', 'Indifferente']"
                outlined
                disabled
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                @input="checkEdit"
                label="Copertina Int.*"
                required
                v-model="material.copertina_int"
                :items="['Bianca', 'Avana', 'Patinata', 'Indifferente']"
                outlined
                disabled
              ></v-select>
            </v-col>
            <v-col cols="12" class="nopd">
              <v-card-title>
                <span class="headline">Data di consegna</span>
              </v-card-title>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                @input="checkEdit"
                label="Inserisci una data*"
                v-model="delivery_date"
                type="date"
                :min="today"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="nopd">
              <v-card-title>
                <span class="headline">Palletizzazione</span>
              </v-card-title>
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="palletizzazione"
                :items="pallet"
                label="Seleziona palletizzazione"
                outlined
                disabled
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox
                v-model="legato"
                label="Legato a pacchi"
                value="Legato a pacchi"
                disabled
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox
                v-model="legato"
                label="Film Estensibile"
                value="Film Estensibile"
                disabled
              ></v-checkbox>
            </v-col>
            <v-col v-if="palletizzazione == pallet[0]" cols="12" class="nopd">
              <v-card-title>
                <span class="headline">Tipo di bancale</span>
              </v-card-title>
            </v-col>
            <v-col cols="12" v-if="palletizzazione == pallet[0]">
              <v-select
                v-model="bancale"
                :items="bancali"
                label="Seleziona tipo di bancale"
                outlined
                disabled
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                @input="checkEdit"
                label="Note"
                outlined
                v-model="notes_prod"
                disabled
              >
              </v-textarea>
              <v-row class="files nopd">
                <v-col sm="9" class="form-allegato force-h">
                  <form
                    enctype="multipart/form-data"
                    id="allegato-prod"
                    class="form-ica"
                  >
                    <label class="uploader">
                      <div v-if="prod_files.length == 0">
                        Non sono presenti file...
                      </div>

                      <v-row
                        class="allegato-row"
                        v-for="(fname, index) in prod_files"
                        :key="index"
                      >
                        <v-col class="nopd center_y" sm="10">{{
                          fname.replace(/_/g, " ")
                        }}</v-col>
                        <v-col class="nopd" sm="2">
                          <v-col cols="12" class="nopd right">
                            <v-icon
                              class="action-file"
                              @click="downloadFile(fname, 'prod')"
                            >
                              mdi-download
                            </v-icon>
                            <v-icon
                              class="action-file"
                              @click="deleteprodfiles(index)"
                            >
                              mdi-delete
                            </v-icon>
                          </v-col>
                        </v-col>
                      </v-row>
                    </label>
                  </form>
                </v-col>
                <v-col sm="3" class="input-ica">
                  <div class="upload-icon">
                    <v-icon color="primary" dark>mdi-upload </v-icon>
                    <p style="font-size: 13px">
                      Trascina i file qui per caricarli
                    </p>
                  </div>
                  <input
                    @change="uploadprodFiles"
                    id="upload-false_prod-file"
                    class="nicenice"
                    name="prod-file"
                    type="file"
                    size="1"
                    multiple
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_production = false">
            Annulla
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirmProduction">
            Conferma Produzione
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "../plugins/axios";
import { bus } from "../main";
import { mapPriorityStatus, mapPriorityStatusReverse } from "../plugins/utils";

import TimeRow from "../components/ProjectComponents/TimeRow.vue";
import PlusTime from "../components/PlusTime.vue";
import missingInformationButton from "../components/missingInformationButton.vue";
import BoxFile from "../components/ProjectComponents/BoxFile.vue";
import PrimaryBtn from "../components/ui/PrimaryBtn.vue";
import ActionElement from "../components/ui/ActionElement.vue";
import WorkCounter from "../components/WorkCounter.vue";
import ArProjectModal from "../components/ProjectComponents/ArProjectModal.vue";
import ArCard from "../components/ProjectComponents/ArCard.vue";
import BctModal from "../components/ProjectComponents/BctModal.vue";
import BctListItem from "../components/ProjectComponents/BctListItem.vue";
import CounterBadge from "../components/ProjectComponents/CounterBadge.vue";
import QuotationItem from "../components/QuotationComponents/QuotationItem.vue";
import CustomerModal from "../components/ProjectComponents/NewCustomerProject/CustomerModal.vue";
import NotesComponent from "../components/ProjectComponents/NotesComponent/NotesComponent.vue";
import CreationModal from "../components/QuotationComponents/CreationModal.vue";

import EventBus from "../plugins/eventBus";

export default {
  name: "Project",
  components: {
    CreationModal,
    TimeRow,
    PlusTime,
    missingInformationButton,
    BoxFile,
    PrimaryBtn,
    WorkCounter,
    ArProjectModal,
    ArCard,
    BctModal,
    BctListItem,
    CounterBadge,
    QuotationItem,
    CustomerModal,
    NotesComponent,
  },

  data() {
    return {
      fustella_model: {
        machine_supplier: "",
        tp: "",
        resa: 0,
        referrer_notes: "",
        suppliers: [],
        is_saved: 0,
        has_sent: 0,
        status: 0,
        delegate: "",
        ref: "",
        type: "fustella",
      },
      stampa_model: {
        machine_supplier: "",
        tp: "",
        resa: 0,
        referrer_notes: "",
        suppliers: [],
        is_saved: 0,
        has_sent: 0,
        status: 0,
        delegate: "",
        ref: "",
        type: "stampa",
        rif_code: "",
      },
      scatola_model: {
        supplier: "",
        qty: [{ qty: "", price: "", resa: "", compo: "" }],
        notes: "",
        is_saved: 0,
        type: "box",
        ref: "",
        delegate: "",
        status: 0,
        is_editing: 0,
      },
      quotationRequestType: "quotation",
      isNew: false,
      isMobileChatVisible: false,
      isLocked: false,
      file_type: "",
      selected_ica_files: [],
      selected_customer_files: [],
      multiple_ica_files: false,
      multiple_customer_files: false,
      isLoading: {
        files: false,
        bct: {
          saving: false,
        },
        quotation: {
          creating: false,
          fustella: {
            update: false,
          },
          stampa: {
            update: false,
          },
        },
        actions: false,
        saving: false,
      },
      hasScrolled: false,
      selected_prev_type: "",

      new_prev_dialog: false,
      no_preview: false,
      requested_file: "",
      filePreviewDialog: false,
      current_uploading: [],
      fileDialog: false,
      form_file: undefined,
      supported_ico_ext: ["jpg", "jpeg", "png", "ard", "pdf", "ai"],
      apiPath: axios.defaults.baseURL,
      actions_dialog: false,
      delegates: ["Jacopo", "Marco"],
      secondary_referrers: [
        "Chiara",
        "Fabiano",
        "Gianfranco",
        "Luca",
        "Monica",
        "Roberta",
        "Roberto",
        "Valentina",
      ],
      alert: false,
      unuploaded_files: [],
      existing_files: [],
      fab: false,
      est_or_int: null,
      are_dimensions: false,
      box_price_quotations: [],
      delivery_date: "",
      new_payment: { request: { amount: 0, is_scalated: 0, to_scale: 0 } },
      new_deposite_dialog: false,
      current_answer: [],
      delegate: "",
      quotation_files: new FormData(),
      response_type: "success",
      tabs: "mobile-tabs-5-1",
      current_msg: "",
      production_status: "",
      dialog_priority: false,
      priority: "",
      priorities: ["ordine in corso", "urgente", "standard", "non urgente"],
      current_user: [],
      imballo: "",
      comp: "",
      es_l: "",
      es_p: "",
      es_h: "",
      int_l: "",
      int_p: "",
      int_h: "",
      dim_int: "",
      fefco: "",
      stampa: "",
      colori: "",
      ritiro: "",
      material: {},
      pallet: ["Su bancale", "Master a croce", "Americana 60x40", "Nessuna"],
      palletizzazione: "",
      legato: [],
      bancale: "",
      bancali: [
        "Generico 80x120",
        "Generico 100x120",
        "EPAL Prima Scelta",
        "EPAL Seconda Scelta",
        "Bancale fuori misura",
      ],

      canEdit: undefined,
      isDisabled: false,
      isEditing: "",
      overlay: "",

      customers: [],

      dialog: false,
      dialog_production: false,
      dialog_delegate: false,

      new_name: "",
      new_address: "",
      new_city: "",
      new_cap: "",
      new_province: "",
      new_country: "",
      new_phone: "",
      new_email: "",
      new_ref: "",
      new_site: "",
      time: "",
      date: "",
      timestamp: "",
      start_time: {},
      end_time: {},
      tracciato: [],
      start_time_tracciato: "",
      end_time_tracciato: "",
      requests: [],

      time_tracciato: [],

      success: false,
      success_text: "",
      loading: false,
      file_uploader: "",

      prod_files: [],
      files_prod_list: new FormData(),

      preview: "",
      result_time: "",
      edited: false,
      uploading: 0,

      pieces_prod: 0,
      notes_prod: "",
      is_p_saved: undefined,
      suppliers: [],
      msgBox: false,
      machineSuppliers: [],
    };
  },

  created: async function () {
    this.checkIfMobile();
    await this.getProject();
    bus.$on("getTimes", () => {
      axios.get("/times/" + this.project.id).then((response) => {
        this.project.tracciato = response.data.times.tracciato;
        this.project.mastrino = response.data.times.mastrino;
      });
    });

    window.addEventListener("beforeunload", this.askConfirm);
  },
  updated: function () {
    if (!this.hasScrolled) {
      this.Scroll();
    }
    var index;
    if (!this.user.priority_limit.urgente.allocated.includes(this.project.id)) {
      if (this.user.priority_limit.urgente.limit == 0) {
        index = this.priorities.indexOf("urgente");
        if (index > -1) {
          this.priorities.splice(index, 1);
        }
      }
    }

    if (
      !this.user.priority_limit.in_corso.allocated.includes(this.project.id)
    ) {
      if (this.user.priority_limit.in_corso.limit == 0) {
        index = this.priorities.indexOf("ordine in corso");
        if (index > -1) {
          this.priorities.splice(index, 1);
        }
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.edited == true) {
      this.$confirm("Hai dati non salvati, sei sicuro di voler uscire?").then(
        (res) => {
          if (res) {
            next();
          } else {
            next(false);
          }
        }
      );
    } else {
      next();
    }
  },
  destroyed: function () {
    this.hasScrolled = false;
    this.closeProject();
  },

  watch: {
    project: {
      handler(val, oldVal) {
        var inputs = document.querySelectorAll("input");
        if (val.status == "in lavorazione" && this.user.role != "master") {
          this.isDisabled = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    sortedTalkProject() {
      return Object.keys(this.project.talk[0])
        .sort((a, b) => {
          let dateA = a.split("-").reverse().join("-");
          let dateB = b.split("-").reverse().join("-");

          return new Date(dateA) - new Date(dateB);
        })
        .reduce((obj, key) => {
          obj[key] = this.project.talk[0][key];
          return obj;
        }, {});
    },
    priorityStatus: {
      get() {
        return this.handlePriorityStatus(this.project.priority_status);
      },
      set(value) {
        this.project.priority_status = this.handlePriorityStatus(value, true);
      },
    },
    project: {
      get() {
        return this.$store.state.project;
      },
      set(val) {
        this.$store.dispatch("setProject", val);
      },
    },
    customer_files() {
      var customers_files = [];
      this.project.customer_files.filter((file) => {
        if (file.type == "customer" && !file.archived) {
          customers_files.push(file);
        }
      });

      return customers_files;
    },
    archivedCustomer() {
      var customers_files = [];
      this.project.customer_files.filter((file) => {
        if (file.type == "customer" && file.archived) {
          customers_files.push(file);
        }
      });
      return customers_files;
    },
    three_ds() {
      var three_ds = [];
      this.project.customer_files.filter((file) => {
        if (file.type == "3d" && !file.archived) {
          three_ds.push(file);
        }
      });
      return three_ds;
    },
    archivedThreeD() {
      var three_ds = [];
      this.project.customer_files.filter((file) => {
        if (file.type == "3d" && file.archived) {
          three_ds.push(file);
        }
      });
      return three_ds;
    },
    schede_tecniche() {
      var schede = [];
      this.project.ica_files.filter((file) => {
        if (file.type == "scheda_tecnica" && !file.archived) {
          schede.push(file);
        }
      });

      return schede;
    },
    archivedSchede() {
      var schede = [];
      this.project.ica_files.filter((file) => {
        if (file.type == "scheda_tecnica" && file.archived) {
          schede.push(file);
        }
      });
      return schede;
    },
    mastrini() {
      var mastrini = [];
      this.project.ica_files.filter((file) => {
        if (file.type == "mastrino" && !file.archived) {
          mastrini.push(file);
        }
      });

      return mastrini;
    },
    archivedMastrini() {
      var mastrini = [];
      this.project.ica_files.filter((file) => {
        if (file.type == "mastrino" && file.archived) {
          mastrini.push(file);
        }
      });
      return mastrini;
    },
    isWorking() {
      if (this.project.status == "in lavorazione") {
        return true;
      } else {
        return false;
      }
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    today() {
      return new Date().toISOString().split("T")[0];
    },
    customers_list() {
      var customers = [];
      this.customers.forEach((customer) => {
        if (customer.name && !customers.includes(customer.name)) {
          customers.push(customer);
        }
      });
      return customers;
    },
  },
  methods: {
    handleQuotationCreated(data) {
      console.log(data);
      if (data.quotationType == "preventivi_scatola") {
        this.project.preventivi_scatola.push(data.quotation);
      } else if (data.quotationType == "preventivi_fustella") {
        if (!data.isNew) this.project.preventivi_fustella.push(data.quotation);
        else this.project.quotations.push(data.quotation);
      } else if (data.quotationType == "preventivi_stampa") {
        if (!data.isNew) this.project.preventivi_stampa.push(data.quotation);
        else this.project.quotations.push(data.quotation);
      }
    },
    deleteGeneratedSupplier(item) {
      this.stampa_model.suppliers.splice(
        this.stampa_model.suppliers.indexOf(item),
        1
      );
    },
    async getProject() {
      let project = {};
      this.path = axios.defaults.baseURL.split("api")[0];
      await axios.get("project/" + this.$route.params.id).then((response) => {
        project = response.data.project;
        this.machineSuppliers = response.data.machine_suppliers;

        this.prod_files = response.data.project.prod_files;

        project.bct = JSON.parse(project.bct);
        project.ar_projects = JSON.parse(project.ar_projects);
        document.title = this.$route.meta.title + " N° " + project.id;
        this.secondary_referrers = response.data.agents;

        if (project.priority == "scaduto") {
          this.project.priority = "standard";
        }

        if (project.secondary_referrer == "null") {
          project.secondary_referrer = "";
        }

        if (project.progettazione_tecnica.dim_est.l) {
          this.est_or_int = "Esterne";
          this.are_dimensions = true;
        } else if (project.progettazione_tecnica.dim_int.l) {
          this.est_or_int = "Interne";
          this.are_dimensions = true;
        }

        this.suppliers = response.data.suppliers;

        if (project.customer == "" && project.name == "") {
          this.is_p_saved = false;
        } else {
          this.is_p_saved = true;
        }

        this.current_user = response.data.current_user;
        this.canEdit = response.data.canEdit;
        this.customers = response.data.customers;

        try {
          this.delivery_date = response.data.data_prod.delivery_date;
        } catch {}

        try {
          this.delivery_date = response.data.data_prod.delivery_date;
        } catch {}

        try {
          this.palletizzazione = response.data.data_prod.pallet;
        } catch {}
        try {
          this.bancale = response.data.data_prod.bancale;
        } catch {}
        try {
          this.legato = response.data.data_prod.legato;
        } catch {}
        try {
          this.pieces_prod = response.data.data_prod.qty;
        } catch {}
        try {
          this.notes_prod = response.data.data_prod.notes;
        } catch {}
        try {
          this.material = response.data.material;
        } catch {}

        if (
          this.$store.state.unlockedProject &&
          this.$store.state.unlockedProject != project.id &&
          this.user.role == "master"
        ) {
          this.isLocked = true;
        }

        if (project.tracciato && project.tracciato.length > 0) {
          if (project.tracciato[project.tracciato.length - 1].start_time) {
            this.start_time_tracciato =
              project.tracciato[project.tracciato.length - 1].start_time;
          }
          if (project.tracciato[project.tracciato.length - 1].end_time) {
            this.end_time_tracciato =
              project.tracciato[project.tracciato.length - 1].end_time;
          }
        }

        this.$store.dispatch("setProject", project);

        this.openProject();
        this.view();

        if (
          this.user.role == "master" &&
          this.current_user != project.delegate
        ) {
          this.dialog_delegate = true;
        }
      });
    },
    handleQuotationDelete(data) {
      const filteredQuotations = this.project.quotations.filter(
        (item) => item.type == data.type
      );

      // If you're certain that data.index is in sync with the filtered list
      const indexToRemove = data.index;

      // Or, if you need to identify the item based on another property, you can do:
      // const indexToRemove = filteredQuotations.findIndex(item => item.someProperty == data.someProperty);

      // Remove the item from the original quotations array
      if (indexToRemove !== -1) {
        const indexInOriginal = this.project.quotations.indexOf(
          filteredQuotations[indexToRemove]
        );
        if (indexInOriginal !== -1) {
          this.project.quotations.splice(indexInOriginal, 1);
        }
      }
    },
    sortMessagesByDate(talk) {
      // Get the keys (dates) from the object, sort them, and store them in a sortedKeys array.
      let sortedKeys = Object.keys(talk[0]).sort(
        (a, b) => new Date(a) - new Date(b)
      );

      // Create a new object to store the sorted data.
      let sortedData = {};

      // Use the sortedKeys array to access the data in the object in sorted order.
      sortedKeys.forEach((key) => {
        sortedData[key] = talk[0][key];
      });

      // Assign the sorted data to sortedMessages.
      return [sortedData];
    },
    handlePriorityStatus(priority, isReverted) {
      if (!isReverted) return mapPriorityStatus(priority);
      else return mapPriorityStatusReverse(priority);
    },
    checkIfMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile =
        /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent
        );
      isMobile
        ? (this.isMobileChatVisible = false)
        : (this.isMobileChatVisible = true);
    },
    str2bytes(str) {
      var bytes = new Uint8Array(str.length);
      for (var i = 0; i < str.length; i++) {
        bytes[i] = str.charCodeAt(i);
      }
      return bytes;
    },
    bulkFilesAction(action, selector) {
      if (selector == "ica") {
        if (this.selected_ica_files.length == 0) {
          this.$store.dispatch("notificate", {
            text: "Seleziona almeno un file",
            type: "warning",
          });
          return;
        }
      } else if (selector == "customer") {
        if (this.selected_customer_files.length == 0) {
          this.$store.dispatch("notificate", {
            text: "Seleziona almeno un file",
            type: "warning",
          });
          return;
        }
      }
      let form = new FormData();
      let text = "";
      var response_type = "json";
      if (action == "transfer") {
        text = "Vuoi trasferire i file selezionati?";
      }
      if (action == "download") {
        text = "Vuoi scaricare i file selezionati?";
        response_type = "blob";
      }
      this.$confirm(text).then((res) => {
        if (res) {
          form.append("id", this.project.id);
          if (selector == "ica") {
            form.append("ica", JSON.stringify(this.selected_ica_files));
          } else if (selector == "customer") {
            form.append(
              "customer",
              JSON.stringify(this.selected_customer_files)
            );
          }
          form.append("selector", selector);

          axios
            .post("files/" + action, form, { responseType: response_type })
            .then((response) => {
              this.$store.dispatch("notificate", {
                text: response.data.msg,
                type: response.data.msg_type,
              });

              if (selector == "ica") {
                this.selected_ica_files = [];
              } else if (selector == "customer") {
                this.selected_customer_files = [];
              }

              if (action == "download") {
                let blob = new Blob([response.data], {
                  type: "application/octet-stream",
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download =
                  "ICAPROGEST-" +
                  this.project.customer.name +
                  "-" +
                  this.project.name +
                  ".zip";
                link.click();
                this.$store.dispatch("notificate", {
                  text: "Download pronto",
                  type: "success",
                });
              }

              if (action == "delete") {
                if (selector == "ica") {
                  this.project.ica_files = response.data.files;
                } else if (selector == "customer") {
                  this.project.customer_files = response.data.files;
                }
              }
            });
        }
      });
    },
    handleStopping() {
      this.$confirm("Vuoi fermare la lavorazione?").then((res) => {
        if (res) {
          this.Task("end");
        }
      });
    },
    openPrevDialog(type, isNew = false) {
      this.selected_prev_type = type;
      this.new_prev_dialog = true;
      if (isNew) this.isNew = true;
    },
    taskStatus(status, sender) {
      if (status && sender != "system") {
        return { bg: "tw-bg-grey", text: "Completata" };
      } else if (status && sender == "system") {
        return { bg: "tw-bg-grey" };
      } else {
        return { bg: "tw-bg-azure", text: "Non completata" };
      }
    },

    openProductionDialog() {
      if (this.edited) {
        this.updateProject();
      }
      this.dialog_production = true;
      this.delivery_date = "";
    },
    closePreviewDialog() {
      this.filePreviewDialog = false;
      this.preview = window.location.origin + "/static/img/no_preview.png";
      this.no_preview = false;
    },
    handleNoPreview() {
      this.no_preview = true;
      this.preview = window.location.origin + "/static/img/no_preview.png";
    },
    openPreviewDialog(name, type) {
      this.requested_file =
        window.location.origin +
        "/static/files/" +
        this.project.id +
        "/" +
        type +
        "/" +
        name;
      var ext = name.split(/[\s.]+/);
      ext = ext[ext.length - 1];
      if (ext != "pdf") {
        this.preview = this.requested_file;
      } else {
        var path =
          window.location.origin +
          "/static/files/" +
          this.project.id +
          "/" +
          type +
          "/previews/";
        this.preview = path + name.split(".")[0] + "_preview" + ".jpg";
      }

      window.open(this.preview, "_blank");
    },
    closeFileDialog() {
      this.fileDialog = false;
      this.current_uploading = [];
    },
    openUploadFileDialog(form, type) {
      this.file_type = type;
      this.form_file = form;
      this.fileDialog = true;
    },
    getFileIco(fname) {
      var ext = fname.split(/[\s.]+/);
      ext = ext[ext.length - 1];
      if (this.supported_ico_ext.includes(ext)) {
        return ext;
      } else {
        return "generic";
      }
    },
    toggleBox(action) {
      if (this.msgBox && action != "opening") {
        this.msgBox = false;
      } else {
        this.msgBox = true;
      }
    },
    setTalk(talk) {
      this.project.talk = talk;
    },
    missingInfo() {
      this.$refs.missing_info.openDialog();
    },
    transferData(type, fname = "", talk = "") {
      if (this.project.id_esterno) {
        if (type == "ica_file" && fname != "") {
          this.$confirm("Vuoi trasferire il file?").then((res) => {
            if (res) {
              axios
                .post(
                  "/transfer/" +
                    this.project.id_esterno +
                    "/" +
                    this.project.id,
                  { ica_file: fname }
                )
                .then((response) => {
                  this.$store.dispatch("notificate", {
                    text: response.data.msg,
                    type: response.data.msg_type,
                  });
                });
            }
          });
        }
      }
    },
    checkForm(classname) {
      var forms = document.getElementsByClassName(classname);
      for (var i = 0; i < forms.length; i++) {
        if (!forms[i].reportValidity()) {
          return false;
        }
      }
      return true;
    },

    calcStatus(items) {
      var somma = 0;

      if (items.length > 0) {
        for (var i = 0; i < items.length; i++) {
          somma = somma + items[i].status;
        }

        var media = somma / items.length;

        if (media < 1 || media == 0) {
          return 0;
        } else if (media < 2 || media == 1) {
          return 1;
        } else if (media == 2) {
          return 2;
        }
      }
    },

    filterSuppliers(items, type) {
      var new_items = [];
      if (type == "fustella") {
        new_items = items.filter((item) => item.fustella.length > 0);

        return new_items;
      } else if (type == "stampa") {
        new_items = items.filter((item) => item.impianto_stampa.length > 0);
        return new_items;
      }
    },
    generateSuppliers(suppliers, type) {
      var suppliers_list = [];
      var items = this.filterSuppliers(suppliers, type);

      if (type == "fustella" && this.fustella_model.machine_supplier) {
        for (var i = 0; i < items.length; i++) {
          if (items[i].name == this.fustella_model.machine_supplier) {
            for (var j = 0; j < items[i].fustella.length; j++) {
              suppliers_list.push({
                supplier: items[i].fustella[j],
                price: 0,
                status: 0,
                has_sent: 0,
                is_editing: 0,
                notes: "",
                master_notes: "",
                id: j,
              });
            }
          }
        }

        this.fustella_model.suppliers = suppliers_list;
      } else if (type == "stampa" && this.stampa_model.machine_supplier) {
        for (var x = 0; x < items.length; x++) {
          if (items[x].name == this.stampa_model.machine_supplier) {
            for (var z = 0; z < items[x].impianto_stampa.length; z++) {
              suppliers_list.push({
                supplier: items[x].impianto_stampa[z].name,
                price: 0,
                status: 0,
                has_sent: 0,
                is_editing: 0,
                notes: "",
                master_notes: "",
                id: z,
              });
            }
          }
        }

        this.stampa_model.suppliers = suppliers_list;
      } else {
        suppliers_list = [];
        this.stampa_model.suppliers = suppliers_list;
        this.fustella_model.suppliers = suppliers_list;
      }
    },

    newPayment() {
      this.new_deposite_dialog = true;

      if (this.new_payment.request.is_scalated == 0) {
        delete this.new_payment.request["to_scale"];
      }

      this.new_payment.id = this.project.id;
      this.new_payment.customer = this.project.customer.name;
      this.new_payment.rif = this.project.name;

      axios
        .post("new-payment/" + this.project.id, {
          payment: JSON.stringify(this.new_payment),
        })
        .then((response) => {
          this.project.payments.push(response.data.new_payment);
          this.new_deposite_dialog = false;
          this.$store.dispatch("notificate", {
            text: "Pagamento creato correttamente",
            type: "success",
          });
        });
    },

    updatePayment(type, i) {
      var form = new FormData();
      form.append("type", type);

      axios
        .post("update-payment/" + this.project.id + "/" + i, form)
        .then((response) => {
          this.project.payments = response.data.payments;
          this.$store.dispatch("notificate", {
            text: response.data.msg,
            type: "success",
          });
        });
    },
    deletePayment(i) {
      this.$confirm(
        "Sei sicuro di voler eliminare il pagamento? Potrai comunque ricrearlo in un secondo momento."
      ).then((res) => {
        if (res) {
          axios
            .post("delete-payment/" + this.project.id + "/" + i)
            .then((response) => {
              this.project.payments = response.data.payments;
              this.$store.dispatch("notificate", {
                text: response.data.msg,
                type: "success",
              });
            });
        }
      });
    },

    newDispute() {
      this.$confirm("Sei sicuro di voler creare una contestazione?").then(
        (res) => {
          if (res) {
            var form = new FormData();
            form.append("id_project", this.project.id);
            form.append("customer", this.project.customer.name);
            form.append("rif", this.project.name);

            axios.post("new-dispute", form).then((response) => {
              //QUA è DA OTTIMIZZARE SENZA RICHIAMARE I DATI (CE NE SONO ALTRE DA OTTIMIZZARE)
              this.$router
                .push("/dispute/" + response.data.last_created)
                .catch(() => {});
            });
          }
        }
      );
    },

    // sendTask(obj){

    //   ipcRenderer.send('focus-task', obj);
    // },

    // sendWork(obj){

    //   ipcRenderer.send('start-work', obj);
    // },

    Scroll() {
      //RIMUOVERE COMMENTI

      var content = this.$refs.messagesContainer;
      if (content) {
        content.scrollTop = content.scrollHeight;
        this.hasScrolled = true;
      }
    },
    dragStart(e, fname, type) {
      //e.dataTransfer.effectAllowed = "copyMove";
      e.dataTransfer.setData(
        "DownloadUrl",
        "application/octet-stream:" +
          fname +
          ":http://" +
          window.location.host +
          "/static/files/" +
          this.project.id +
          "/" +
          type +
          "/" +
          fname
      );
    },
    dict_reverse(obj) {
      var new_obj = {};
      var rev_obj = Object.keys(obj).reverse();
      rev_obj.forEach(function (i) {
        new_obj[i] = obj[i];
      });
      return new_obj;
    },

    addqtyQuotation() {
      this.scatola_model.qty.push({ qty: "", price: "", resa: "", compo: "" });
    },

    deleteqtyQuotation(i) {
      this.scatola_model.qty.splice(i, 1);
    },
    validateQuotation(type) {
      if (this.isNew && type == "preventivi_stampa") {
        if (
          !this.stampa_model.suppliers.length ||
          !this.stampa_model.resa ||
          !this.stampa_model.tp ||
          !this.stampa_model.rif_code ||
          (!this.stampa_model.price && this.quotationRequestType == "order")
        ) {
          this.$store.dispatch("notificate", {
            text: "Compila tutti i campi",
            type: "warning",
          });
          return false;
        }
        if (
          this.quotationRequestType == "order" &&
          this.stampa_model.suppliers.length > 1
        ) {
          this.$store.dispatch("notificate", {
            text: "Seleziona solo fornitore se vuoi mandare un ordine diretto",
            type: "warning",
          });
          return false;
        }
      }
      return true;
    },
    createQuotation: function (type) {
      var obj = {};
      var quotationId = null;

      if (!this.validateQuotation(type)) return;

      if (this.checkForm("quotation-form")) {
        if (type == "preventivi_fustella") {
          quotationId =
            this?.project?.preventivi_fustella[
              this.project?.preventivi_fustella?.length - 1
            ]?.id;
          this.fustella_model.delegate = this.project.delegate;
          this.fustella_model.ref = this.project.referrer;
          obj = this.fustella_model;
          obj.machine_supplier = this.fustella_model.machine_supplier;
        } else if (type == "preventivi_stampa") {
          quotationId =
            this?.project?.preventivi_stampa[
              this.project?.preventivi_stampa?.length - 1
            ]?.id;
          this.stampa_model.delegate = this.project.delegate;
          this.stampa_model.ref = this.project.referrer;
          obj = { ...this.stampa_model };
          obj.machine_supplier = this.stampa_model.machine_supplier;
        } else if (type == "preventivi_scatola") {
          quotationId =
            this?.project?.preventivi_scatola[
              this.project?.preventivi_scatola?.length - 1
            ]?.id;
          this.scatola_model.delegate = this.project.delegate;
          this.scatola_model.ref = this.project.referrer;
          obj = this.scatola_model;
          obj.supplier = this.scatola_model.supplier.name;
        } else {
        }

        obj.requestType = this.quotationRequestType;

        obj.id = quotationId;
        this.quotation_files.append("quotation", JSON.stringify(obj));
        this.isLoading.quotation.creating = true;

        axios
          .post(
            (this.isNew ? "" : "old-") +
              "quotation/" +
              this.project._id +
              "/" +
              type,
            this.quotation_files
          )
          .then((response) => {
            if (type == "preventivi_scatola") {
              this.project.preventivi_scatola.push(response.data.quotation);
            } else if (type == "preventivi_fustella") {
              this.project.preventivi_fustella.push(response.data.quotation);
            } else if (type == "preventivi_stampa") {
              if (!this.isNew)
                this.project.preventivi_stampa.push(response.data.quotation);
              else
                this.project.quotations = [
                  ...this.project.quotations,
                  response.data.quotation,
                ];
            }

            this.$store.dispatch("notificate", {
              text: response.data.msg,
              type: "success",
            });
            this.quotation_files = new FormData();

            this.isLoading.quotation.creating = false;
            this.new_prev_dialog = false;
            this.isNew = false;
          });
      }
    },

    saveQuotation: function (i, type) {
      this.quotation_files.append(
        "quotation",
        JSON.stringify(this.project[type][i])
      );

      axios
        .post("quotation/" + this.project.id + "/" + type, this.quotation_files)
        .then((response) => {
          this.project[type][i].is_saved = 1;
          this.project[type][i].is_editing = 0;
          this.project[type][i].delegate = response.data.quotation.delegate;
          this.project[type][i].status = response.data.quotation.status;
          this.project[type][i].ref = response.data.quotation.ref;

          this.$store.dispatch("notificate", {
            text: response.data.msg,
            type: "success",
          });

          if (type == "preventivi_scatola") {
            this.project[type][i].files = response.data.quotation.files;
          }

          this.quotation_files = new FormData();
        });
    },

    quotationFiles(e) {
      for (var x = 0; x < e.target.files.length; x++) {
        this.quotation_files.append("files", e.target.files[x]);
      }
    },

    updateQuotation: function (i, type, index_supplier, action) {
      if (type == "preventivi_fustella") {
        this.isLoading.quotation.fustella.update = true;
      } else {
        this.isLoading.quotation.stampa.update = true;
      }
      var quotation = new FormData();

      quotation.append("quotation", JSON.stringify(this.project[type][i]));
      quotation.append("files", this.quotation_files);

      axios
        .patch(
          "quotation/" +
            this.project.id +
            "/" +
            i +
            "/" +
            type +
            "/" +
            index_supplier +
            "/" +
            action,
          { quotation: JSON.stringify(this.project[type][i]) }
        )
        .then((response) => {
          if (type == "preventivi_fustella") {
            this.$set(
              this.project.preventivi_fustella,
              i,
              response.data.quotation
            );
            this.isLoading.quotation.fustella.update = false;
          } else if (type == "preventivi_stampa") {
            this.$set(
              this.project.preventivi_stampa,
              i,
              response.data.quotation
            );
            this.isLoading.quotation.stampa.update = false;
          }

          this.$store.dispatch("notificate", {
            text: response.data.msg,
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading.quotation.fustella.update = false;
          this.isLoading.quotation.stampa.update = false;
        });
    },

    deleteQuotation: function (i, type) {
      this.$confirm("Sei sicuro di voler cancellare il preventivo").then(
        (res) => {
          if (res) {
            axios
              .delete(
                "old-quotation/" + this.project._id + "/" + i + "/" + type
              )
              .then((response) => {
                this.project[type].splice(this.project[type].indexOf(i), 1);
                this.$store.dispatch("notificate", {
                  text: response.data.msg,
                  type: "success",
                });
              });
          }
        }
      );
    },

    delegateProject: function () {
      this.$emit("input", this.project.delegate);
      const delegateForm = new FormData();
      delegateForm.append("delegate", this.project.delegate);
      axios
        .post("delegate/" + this.project.id, delegateForm)
        .then((response) => {
          this.$store.dispatch("notificate", {
            text: response.data.msg,
            type: "success",
          });
        });
    },

    setLoader: function () {
      const loader = document.querySelector("#loading");
      loader.style.display = "flex";
      if (this.uploading == 100) {
        setTimeout(function () {
          loader.style.display = "none";
        }, 1500);
      }
    },

    askConfirm: function (e) {
      if (this.edited) {
        var confirmationMessage = "Sium";

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE

        return confirmationMessage;
      }
    },

    getNow: function () {
      const today = new Date();
      const date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
      var h = today.getHours();
      var m = today.getMinutes();
      if (h < 10) {
        h = "0" + h;
      }
      if (m < 10) {
        m = "0" + m;
      }
      const time = h + ":" + m;
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
      this.date = date;
      this.time = time;
    },

    getFinale: function () {
      var hours = 0;
      var minutes = 0;
      var all_times = this.project.tracciato.concat(
        this.project.mastrino,
        this.project.campionatura
      );
      for (var i = 0; i < all_times.length; i++) {
        try {
          hours = hours + parseInt(all_times[i].final_time.split(":")[0]);
          minutes = minutes + parseInt(all_times[i].final_time.split(":")[1]);
        } catch {}
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      var result_time = String(hours) + ":" + String(minutes);
      this.result_time = result_time;
    },

    checkEdit() {
      this.edited = true;
    },

    shareFiles(filename, ref) {
      const fname = new FormData();
      fname.append("filename", filename);
      fname.append("to", ref);
      axios.post("view-file/" + this.project.id, fname).then((response) => {
        if (response.data.msg.type == "success") {
          this.$store.dispatch("notificate", {
            text: response.data.msg.text,
            type: response.data.msg.type,
          });
          let viewPath = window.location.origin;
          if (viewPath.includes("127.0.0.1"))
            viewPath = viewPath.replace(":8080", ":5000");
          window.open(viewPath + "/" + response.data.path);
        } else {
          this.$store.dispatch("notificate", {
            text: response.data.msg.text,
            type: response.data.msg.type,
          });
        }
      });
    },

    downloadProductionFile(filename, type, index) {
      const fname = new FormData();
      fname.append("filename", filename);
      fname.append("type", type);
      fname.append("to", "production");
      axios
        .get("download-production-file/" + this.project.id + "/" + index, {
          responseType: "blob",
        })
        .then((response) => {
          this.$store.dispatch("notificate", {
            text: "File trovato",
            type: "success",
          });
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename.replace(/_/g, " ");
          link.click();
        });
    },

    goBack: function () {
      this.$router.push("/projects").catch(() => {});
    },

    addLineBreak() {
      //var linebreak = document.createElement("br");
      this.getNow();
      var input = document.querySelector("#send").value;
      this.current_msg = input;
      //this.current_msg = this.current_msg +  " " + this.date + " (" + this.time + ")";
    },

    generatePreviews: function () {
      try {
        var allegati = this.ica_files.concat(this.customer_files);
        var i;
        for (i = 0; i < allegati.length; i++) {}
      } catch {}
    },
    uploadFiles: function (e) {
      const filesForm = new FormData();
      var files = e.target.files;

      for (var x = 0; x < e.target.files.length; x++) {
        if (e.target.files[x].size > 7340032) {
          this.unuploaded_files.push(e.target.files[x].name);
        } else {
          filesForm.append(e.target.getAttribute("name"), e.target.files[x]);
          filesForm.append("type", this.file_type);
          this.current_uploading.push({
            name: e.target.files[x].name,
            size: (e.target.files[x].size / 1024 / 1024).toFixed(2),
          });
        }
      }

      let config = {
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.uploading = percentCompleted;
        },
      };

      for (var i = 0; i < files.length; i++) {
        if (e.target.getAttribute("name") == "ica-file") {
          if (this.project.ica_files.includes(files[i].name)) {
            this.existing_files.push(files[i].name);
          }
        } else {
          if (this.project.customer_files.includes(files[i].name)) {
            this.existing_files.push(files[i].name);
          }
        }
      }

      if (this.existing_files.length == 1) {
        this.$confirm(
          "Il file " +
            this.existing_files[0] +
            " è già esistente e verrà sovrascritto. \n Sicuro di voler continuare?"
        ).then((res) => {
          if (!res) {
            return;
          }
        });
      } else if (this.existing_files.length > 1) {
        let msg = "";
        for (var j = 0; j < this.existing_files.length; j++) {
          msg = msg + "\n -" + this.existing_files[j];
        }
        this.$confirm(
          "I seguenti file " +
            msg +
            " \n esistono e verranno sovrascritti. \n Sicuro di voler continuare?"
        ).then((res) => {
          if (!res) {
            return;
          }
        });
      }
      var counter = 0;

      if (!filesForm.entries().next().done) {
        this.isLoading.files = true;
        filesForm.append("id_project", this.project.id);
        axios.post("upload-file/", filesForm, config).then((response) => {
          this.project.talk = response.data.timeline;
          this.$store.dispatch("notificate", {
            text: response.data.msg,
            type: "success",
          });
          if (e.target.getAttribute("name") == "ica-file") {
            this.project.ica_files = response.data.ica_files;
          } else if (e.target.getAttribute("name") == "customer-file") {
            this.project.customer_files = response.data.customer_files;
          }
          this.generatePreviews();
          if (this.form_file == "ica-file") {
            this.$refs.ica_files.value = null;
          } else {
            this.$refs.customer_files.value = null;
          }
          this.isLoading.files = false;

          this.selected_ica_files = [];
          this.selected_customer_files = [];
        });
      }

      this.existing_files = [];
      if (this.unuploaded_files.length > 0) {
        this.alert = true;
      }
    },

    closeFilesAlert() {
      this.alert = false;
      this.unuploaded_files = [];
    },

    uploadprodFiles: function (e) {
      var files = e.target.files;

      for (var x = 0; x < e.target.files.length; x++) {
        this.files_prod_list.append(
          e.target.getAttribute("name"),
          e.target.files[x]
        );
        this.prod_files.push(e.target.files[x].name);
      }
    },

    confirmProduction: function () {
      try {
        parseInt(this.pieces_prod);
      } catch {
        this.$store.dispatch("notificate", {
          text: "La quantità deve essere un numero",
          type: "warning",
        });
        return;
      }

      if (this.checkForm("production-confirm")) {
        this.files_prod_list.append("qty", this.pieces_prod);
        this.files_prod_list.append("notes", this.notes_prod);
        this.files_prod_list.append("material", JSON.stringify(this.material));
        this.files_prod_list.append("pallet", this.palletizzazione);
        this.files_prod_list.append("legato", this.legato);
        this.files_prod_list.append("bancale", this.bancale);
        this.files_prod_list.append("delivery", this.delivery_date);
        this.files_prod_list.append("priority", this.project.priority);

        let config;

        if (this.user.role == "master") {
          config = {
            onUploadProgress: (progressEvent) => {
              let percentCompleted = Math.floor(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.uploading = percentCompleted;
              this.setLoader();
            },
          };
        } else {
          config = null;
        }

        axios
          .post("to-prod/" + this.project.id, this.files_prod_list, config)
          .then((response) => {
            this.project.production_status = response.data.produzione.status;
            this.project.productions = response.data.produzione;
            if (response.data.produzione.status == "file da caricare") {
              this.project.to_production = "yes";
            }

            this.$store.dispatch("notificate", {
              text: response.data.msg,
              type: "success",
            });
            this.files_prod_list = new FormData();
            this.edited = false;
            this.dialog_production = false;
          });
      }
    },

    filePreviewica: function (name) {
      var path =
        window.location.origin +
        "/static/files/" +
        this.project.id +
        "/ica/previews/";
      this.preview = path + name.split(".")[0] + "_preview" + ".jpg";
      // DA OTTIMIZZARE LE IMMAGINI DA BACKEND
      //  if (name.split(".")[1] == "jpg" || name.split(".")[1] == "png"){
      //    path  = "http://local.icaproject.com:8081/assets/uploads/files/" + this.$route.params.id + "/ica/";
      //    this.preview = path + name;

      //}
    },

    filePreviewcustomer: function (name) {
      var path =
        window.location.origin +
        "/static/files/" +
        this.project.id +
        "/customer/previews/";
      //var path  = "http://192.168.1.50:8080/assets/uploads/files/" + this.$route.params.id + "/customer/previews/";
      this.preview = path + name.split(".")[0] + "_preview" + ".jpg";
      // DA OTTIMIZZARE LE IMMAGINI DA BACKEND
      //if (name.split(".")[1] == "jpg" || name.split(".")[1] == "png"){
      //path  = "http://192.168.1.50:8081/assets/uploads/files/" + this.$route.params.id + "/customer/";
      //this.preview = path + name;

      //}
    },

    deleteICAfiles: function (number) {
      this.$confirm("Sei sicuro di voler cancellare il file?").then((res) => {
        if (res) {
          axios
            .post("delete-ica-file/" + this.$route.params.id + "/" + number)
            .then((response) => {
              this.project.talk = response.data.timeline;
              this.project.ica_files = response.data.ica_files;
              this.$store.dispatch("notificate", {
                text: response.data.msg,
                type: "success",
              });
              this.selected_ica_files = [];
            });
        }
      });
    },
    deleteprodfiles: function (number) {
      this.$confirm("Sei sicuro di voler cancellare il file?").then((res) => {
        if (res) {
          axios
            .post("delete-prod-file/" + this.project.id + "/" + number)
            .then((response) => {
              if (response.data.msg == "not found") {
              } else {
                this.$store.dispatch("notificate", {
                  text: response.data.msg,
                  type: "success",
                });
                this.prod_files = response.data.prod_files;
              }
            });
        }
      });
    },
    archiveFile(fileList, filename) {
      this.$confirm(
        "Sei sicuro di voler archiviare il file " + filename.name + "?"
      ).then((res) => {
        if (res) {
          axios
            .patch(`project/${this.project._id}/archive-file/`, {
              file_to_archive: filename.name,
              file_list_name: fileList,
            })
            .then((response) => {
              fileList == "ica_files"
                ? (this.project.ica_files = response.data.file_list)
                : (this.project.customer_files = response.data.file_list);
              this.$store.dispatch("notificate", {
                text: response.data.message,
                type: "success",
              });
            })
            .catch((err) => {
              this.$store.dispatch("notificate", {
                text: "C'è stato un errore durante l'archiviazione del file",
                type: "error",
              });
            });
        }
      });
    },
    recoverFile(fileList, filename) {
      this.$confirm(
        "Sei sicuro di voler ripristinare il file " + filename.name + "?"
      ).then((res) => {
        if (res) {
          axios
            .patch(`project/${this.project._id}/recover-file/`, {
              file_to_recover: filename.name,
              file_list_name: fileList,
            })
            .then((response) => {
              fileList == "ica_files"
                ? (this.project.ica_files = response.data.file_list)
                : (this.project.customer_files = response.data.file_list);
              this.$store.dispatch("notificate", {
                text: response.data.message,
                type: "success",
              });
            })
            .catch((err) => {
              this.$store.dispatch("notificate", {
                text: "C'è stato un errore durante il ripristino del file",
                type: "error",
              });
            });
        }
      });
    },
    downloadFile(filename, selector) {
      var form = new FormData();
      form.append("id", this.project.id);
      form.append("filename", JSON.stringify(filename));
      form.append("selector", selector);

      axios
        .post("download-file/", form, { responseType: "blob" })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename.name.replace(/_/g, " ");
          link.click();
        });
    },
    deleteFile(filename, selector) {
      var form = new FormData();
      form.append("id", this.project.id);
      form.append("filename", JSON.stringify(filename));
      form.append("selector", selector);

      this.$confirm(
        "Sei sicuro di voler cancellare il file " + filename.name + "?"
      ).then((res) => {
        if (res) {
          axios.post("delete-file/", form).then((response) => {
            this.project.talk = response.data.timeline;
            this.$store.dispatch("notificate", {
              text: response.data.msg,
              type: "success",
            });
            if (selector == "ica") {
              this.project.ica_files = response.data.files;
              this.selected_ica_files = [];
            } else if (selector == "customer") {
              this.project.customer_files = response.data.files;
              this.selected_customer_files = [];
            }
          });
        }
      });
    },
    downloadRequestedFile() {
      window.open(this.requested_file);
    },
    deleteTime(index, field) {
      this.$confirm(
        "Sei sicuro di voler cancellare l'orario di lavorazione?"
      ).then((res) => {
        if (res) {
          axios
            .post("time-delete/" + this.project.id + "/" + field + "/" + index)
            .then((response) => {
              if (response.data.field == "tracciato") {
                this.project.tracciato = response.data.data_time;
              } else if (response.data.field == "mastrino") {
                this.project.mastrino = response.data.data_time;
              }
              this.$store.dispatch("notificate", {
                text: response.data.msg,
                type: "success",
              });
            });
        }
      });
    },
    deleteCustomerfiles: function (number) {
      this.$confirm("Sei sicuro di voler cancellare il file?").then((res) => {
        if (res) {
          axios
            .post("delete-customer-file/" + this.project + "/" + number)
            .then((response) => {
              this.project.talk = response.data.timeline;
              this.project.customer_files = response.data.customer_files;
              this.$store.dispatch("notificate", {
                text: response.data.msg,
                type: "success",
              });
            });
        }
      });
    },

    updateProject: function () {
      if (this.checkForm("generic-data")) {
        this.isLoading.saving = true;

        axios
          .patch("project/" + this.project.id, this.project)
          .then((response) => {
            this.$socket.send("project-update");
            EventBus.$emit("data-received", "project-updated");
            this.project = response.data.project;
            this.edited = false;
            if (this.project.customer.name == "" && this.project.name == "") {
              this.is_p_saved = false;
            } else {
              this.is_p_saved = true;
            }
            this.isLoading.saving = false;
            this.$store.dispatch("notificate", {
              text: response.data.msg,
              type: "success",
            });
          });
      }
    },

    newTask() {
      if (this.checkForm("generic-data")) {
        const formData = new FormData();
        this.project.last_msg = this.current_msg;
        this.project.priority_status = this.handlePriorityStatus(
          this.priorityStatus,
          true
        );

        if (this.project.last_msg != "" && !this.dialog_priority) {
          this.dialog_priority = true;
        } else {
          delete this.project["esterno"];
          axios
            .post("new-task", { project: JSON.stringify(this.project) })
            .then((response) => {
              if (response.data.alert) {
                this.$confirm(response.data.msg).then((res) => {
                  if (res) {
                    this.updateProject();
                  } else {
                    this.dialog_priority = false;
                  }
                });
              } else {
                if (response.data.msgStatus == "warning") return;
                this.project.talk = response.data.project.talk;
                this.msgBox = false;
                this.dialog_priority = false;
                this.current_msg = "";
                if (
                  this.project.customer.name == "" &&
                  this.project.name == ""
                ) {
                  this.is_p_saved = false;
                } else {
                  this.is_p_saved = true;
                }
                this.$store.dispatch("notificate", {
                  text: response.data.msg,
                  type: response.data.msgStatus,
                });
              }

              this.project.last_status = "in attesa";
              this.project.priority = response.data.project.priority;
              this.project.priority_status =
                response.data.project.priority_status;
            });
        }
      }
    },

    answerRequest: function (which, when, what) {
      const formData = new FormData();

      formData.append("talk", what);
      formData.append("which", which);
      formData.append("when", when);

      axios.post("answer/" + this.project.id, formData).then((response) => {
        this.current_answer = "";

        this.success_text = "Messaggio inviato";
        this.success = true;
        this.edited = false;
        this.current_msg = "";
        setTimeout(() => (this.success = false), 1400);
      });
    },

    cloneProject: function () {
      this.$confirm("Sei sicuro di voler clonare il seguente progetto?").then(
        (res) => {
          if (res) {
            this.edited = false;
            axios
              .post("project/" + this.project.id + "/clone")
              .then((response) => {
                this.$store.dispatch("notificate", {
                  text: response.data.msg.text,
                  type: response.data.msg.type,
                });
                this.$router.push("/projects").catch(() => {});
              });
          }
        }
      );
    },

    deleteProject: function () {
      this.$confirm(
        "Sei sicuro di voler cancellare il seguente progetto?"
      ).then((res) => {
        if (res) {
          this.edited = false;
          axios.delete("project/" + this.project.id).then((response) => {
            this.$store.dispatch("notificate", {
              text: response.data.msg.text,
              type: response.data.msg.type,
            });
            if (response.data.msg.type != "error")
              this.$router.push("/projects").catch(() => {});
          });
        }
      });
    },

    openProject: function () {
      if (this.project.status != "in lavorazione") {
        axios.post("open/" + this.$route.params.id).then((response) => {
          this.project.status = response.data.status;
          this.project.is_editing = response.data.is_editing;
          if (
            this.project.is_editing != this.user.username &&
            this.project.is_editing != ""
          ) {
            this.isDisabled = true;
          } else {
            this.isDisabled = false;
          }
        });
      }
    },
    closeProject: function () {
      this.hasScrolled = false;
      this.$store.state.project = {};
      axios.post("close/" + this.project.id).then((response) => {});
    },

    updateCustomers(data) {
      this.customers = data.customers;
      this.project.customer = data.customer;
      this.edited = true;
    },
    view: function () {
      if (this.project.status == "aperto" && this.canEdit == 0) {
        this.isDisabled = true;
      }

      if (
        this.project.status == "in lavorazione" &&
        this.user.role != "master"
      ) {
        this.overlay = "overlay-lock";
      }
    },

    Task: function (tipo) {
      const field = "tracciato";
      const tracciato_form = new FormData();
      const get = tipo;
      this.getNow();

      if (get == "start") {
        this.start_time.tracciato = this.timestamp;
        this.start_time_tracciato = this.timestamp;
        this.project.status = "in lavorazione";

        tracciato_form.append("start", this.start_time[field]);
      } else {
        this.end_time.tracciato = this.timestamp;
        this.end_time_tracciato = this.timestamp;
        tracciato_form.append("end", this.end_time[field]);
      }

      axios
        .post("task/" + field + "/" + this.project.id, tracciato_form)

        .then((response) => {
          if (response.data.field == "tracciato") {
            this.project.tracciato = response.data.data_time;
          }

          try {
            this.start_time_tracciato =
              this.project.tracciato[
                this.project.tracciato.length - 1
              ].start_time;
            this.end_time_tracciato =
              this.project.tracciato[
                this.project.tracciato.length - 1
              ].end_time;
          } catch {}

          if (tipo == "end") {
            this.project.status = "in attesa";
          }
        });
    },

    completeProject() {
      this.isLoading.actions = true;
      axios.post("completed/" + this.project.id).then((response) => {
        if (response.data.missingInfo)
          this.$store.dispatch("notificate", {
            text: response.data.msg,
            type: "warning",
          });
        else if (response.data.error)
          this.$store.dispatch("notificate", {
            text: response.data.msg,
            type: "warning",
          });
        else
          this.$store.dispatch("notificate", {
            text: response.data.msg,
            type: "success",
          });

        this.isLoading.actions = false;
      });
    },

    getColor(status) {
      if (status == 0) {
        return "red";
      } else if (status == 1) {
        return "#ffc412";
      } else if (status == 3) {
        return "blue";
      } else {
        return "green";
      }
    },
    getProductionColor(status) {
      if (status == "in attesa") {
        return "#ffc412";
      } else if (status == "aperto") {
        return "blue";
      } else if (status == "in produzione") {
        return "red";
      } else if (status == "completato") {
        return "green";
      } else if (status == "file da caricare") {
        return;
      } else {
        return "#c0c0c0";
      }
    },
    getCurrentStatus(status) {
      if (status == 0) {
        return "in attesa di contabile";
      } else if (status == 1) {
        return "in attesa di bonifico";
      } else if (status == 3) {
        return "Posticipato";
      } else {
        return "Completato";
      }
    },

    getQuotationColor(status) {
      if (status == 0) {
        return "#474747";
      } else if (status == 1) {
        return "#ffc412";
      } else if (status == 2) {
        return "green";
      }
    },
  },
};
</script>

<style scoped>
.active-tab {
  background-color: #ddebeb;
  color: #2f2f2f;
}

.box-f {
  width: 100%;
}

.actions > button {
  margin: 5px;
}

.uncompleted {
  background-color: rgb(243, 255, 255) !important;
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.wh {
  background-color: white;
  margin: 0;
}

.force-h {
  height: 100% !important;
}

.production-allegato {
  min-height: 200px;
}

.mg-5 {
  margin: 2px;
}
.mg-10-l {
  margin-left: 10px;
}

.block-answer {
  width: 100%;
  display: flex;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f0efeb;
  align-items: center;
  border-radius: 200px;
  justify-content: space-around;
}

.block-answer-sent {
  width: 100%;
  min-height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgb(219, 241, 241);
  align-items: center;
  border-radius: 10px;
  justify-content: space-around;
  padding: 10px;
}

.answer {
  padding: 10px;
  width: 80%;
  margin-right: 50px;
  height: 100%;
}
.answer-sent {
  width: 100%;
  margin-right: 50px;
  height: 100%;
}

.center {
  text-align: center;
}

.center_y {
  font-size: 14px;
  display: flex;
  align-items: center;
}

#files-2 {
  margin-top: 10px;
}

.upload-icon {
  text-align: center;
}

.msg-text {
  width: 100%;
  white-space: nowrap;
}

.fix {
  width: auto;
  padding-bottom: 20px;
  position: sticky;
  top: 0;
  margin: -20px;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 30px;
  background-color: rgb(47, 47, 47);
  color: white;
  z-index: 4;
}

.time-content {
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
}

.time-title {
  padding: 0 !important;
}

.loading {
  z-index: 9000;
}

#loading {
  display: none;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.212);
  z-index: 9000;
}

.shape {
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.246);
}

.limit-height {
  max-height: 20%;
}

.content-row {
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  flex-wrap: wrap;
}

.uploader::-webkit-scrollbar {
  width: 5px;
  z-index: 2000;
}

.uploader {
  -ms-overflow-style: 5px; /* IE and Edge */
  scrollbar-width: 5px; /* Firefox */
}

::-webkit-scrollbar {
  width: 2px;
  z-index: 4000;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.action-file {
  cursor: pointer;
}

.nopd {
  padding: 0px !important;
}

.allegato-row {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.185);
  background-color: white;
  flex: none;
  box-sizing: border-box;
  cursor: grab;
}

.allegato-row:hover {
  background-color: rgb(248, 248, 248);
}

.right {
  text-align: right;
}

.uploader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 20px;
}

#upload-false_ica-file,
#upload-false_customer-file,
#upload-false_prod-file {
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.form-ica {
  margin-right: 10px;
  margin-left: 10px;
  height: 100%;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 20px;
  background-color: #f0efeb;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
}

.input-ica {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 20px;
  background-color: #f0efeb;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.allegato-title {
  height: 20%;
  padding: 0px !important;
}

.form-allegato {
  height: 80%;
  padding: 0;
}

.v-tabs {
  height: 10%;
}

.v-item-group {
  height: 90%;
}
.v-item-group >>> .v-window__container,
.v-item-group >>> .v-window-item {
  height: 100%;
}

.v-window {
  overflow: inherit;
  background-color: transparent !important;
}

.tab-content {
  height: 90%;
}

.new-customer {
  cursor: pointer;
}

.new-customer:hover {
  background-color: #f0efeb;
  border-radius: 4px;
}

.overlay-lock {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.664);
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: white;
}

.hide {
  display: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.input-block {
  border: 1px rgb(190, 190, 190) solid;
  width: 100%;
  border-radius: 4px;
}

.elevation-1 {
  width: 100%;
  box-sizing: border-box;
}

.fixed-actions {
  position: fixed;
  bottom: 40px;
  right: 50%;
}

.input-data {
  display: flex;
  height: 100%;
  flex-direction: column;
  font-family: "Open Sans", sans-serif !important;
}

.generic-info {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}

.chat-data {
  width: 25%;
  flex-basis: auto !important;
  height: 100%;
}

.data-info {
  width: 55%;
  flex-basis: auto !important;
  box-sizing: border-box;
  height: 100%;
}
p {
  padding: 0;
  margin: 0;
}

label {
  margin-right: 5px;
}

.actions {
  position: fixed;
  width: 20%;
  height: 75px;
  left: 40%;
  bottom: 5px;
  box-sizing: border-box;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-action {
  width: 33.3%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 5px;
}

.icon-action {
  height: 100%;
  width: 100%;
}

.smaller {
  height: 40px !important;
  width: 40px !important;
}

.icon {
  width: 50px;
  height: 50px;
}

.action-tooltip {
  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action:hover .action-tooltip {
  visibility: visible;
}

.chat {
  border: 1px solid rgb(204, 204, 204);
  height: 100%;
  border-radius: 20px;

  display: flex;
  flex-direction: column-reverse;
}
.chat-view {
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  background-color: white;
  overflow-y: scroll;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.chat-view::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-view {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.chat-view-ciko {
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: scroll;
  border-radius: 20px 20px 0px 0px;
}

.chat-view-ciko::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-view-ciko {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.msg-time {
  margin-top: 20px;
  font-size: 12px;
  float: right;
  margin-bottom: 0 !important;
}

.chat-bar {
  height: 20%;
  box-sizing: border-box;
  padding: 5px 10px 5px 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: inherit;
  background-color: #f0efeb;
  transition: 0.5s;
}
.send-msg {
  width: 10%;
  height: 100% !important;
  min-width: 50px !important;
}

.box_full_h {
  height: 600%;
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -10px 50px;
}

.block-msg {
  width: 100%;
  word-wrap: break-word;
  margin-bottom: 10px;
  white-space: pre-line;
  z-index: 2;
}

.msg {
  background-color: white;
  padding: 21px 20px 5px 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}

.viewer {
  float: right !important;
  background-color: rgb(255, 132, 110) !important;
  border-radius: 15px 0px 15px 15px !important;
}

.system {
  float: none !important;
  background-color: transparent !important;
  text-align: center;
  box-shadow: none;
}

.userlogo {
  text-transform: capitalize;
  color: rgb(240, 90, 97);
}

.file-preview {
  visibility: hidden;
  display: inline-block;
  position: absolute;
  width: 400px;
  height: 400px;
  background-color: white;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 5;
  margin-left: 93%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  bottom: 0;
}

.file-preview-up {
  z-index: 250;
  visibility: hidden;
  display: inline-block;
  position: absolute;
  width: 400px;
  height: 400px;
  background-color: white;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 5;
  margin-left: 93%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  top: 0;
}

.allegato-row:hover .file-preview {
  visibility: visible;
}
.allegato-row:hover .file-preview-up {
  visibility: visible;
}
.dl-time {
  background: white;
}

.isediting {
  position: fixed;
  top: 12%;
  right: 2%;
}

.v-application .primary--text,
.blue--text.text--darken-1 {
  color: rgb(17, 121, 123) !important;
}

.mg-10 {
  margin-top: 10px;
}

.no-mg {
  margin: 0;
}

.unread-requests {
  display: none;
  position: absolute;
  top: -33%;
  width: 100%;
  text-align: center;
  font-weight: 800;
  left: 0;
  z-index: -200000;
  border-radius: 10px;
}

.unread-requests0 {
  display: block !important;
}

.supplier-quotation {
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: rgb(241, 241, 241);
  margin-bottom: 7px;
  margin-top: 7px;
  cursor: default !important;
}

.quot {
  padding: 20px !important;
}
</style>
