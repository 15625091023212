<template>
    <v-row class="!tw-p-0 !tw-mt-12">
        <v-col cols="8" >
            <h1 class="tw-text-3xl tw-font-bold">Operatori di produzione</h1>
        </v-col>
        <v-col cols="4" class="tw-flex tw-flex-col tw-items-end tw-justify-center"> 
            <OperatorDialog @newOperator="getOperators" />
        </v-col>

        <v-data-table class="tw-w-full" v-if="operators" hide-default-footer :headers="headers" :items="operators" loading="true">
            <template v-slot:item.name="{ item }">
                <td>
                    {{ item.name }}
                </td>
            </template>
            <template v-slot:item.actions="{ item }">
                <td class="tw-w-full tw-flex tw-justify-end tw-items-center"> 
                    <EditOperator :operator="item" @newOperator="getOperators" />
                </td>
            </template>
        </v-data-table>
    </v-row>
</template>

<script>
import axios from '../../../plugins/axios';
import EditOperator from './EditOperator.vue';
import OperatorDialog from './NewOperator.vue';

export default {
    name: "Operators",
    components: {
        OperatorDialog,
        EditOperator
    },
    data() {
        return {
            loading: true,
            operators: [],
            headers: [
                {
                    text: "Operatore",
                    align: "start",
                    sortable: false,
                    value: "name",
                },
                { text: "Azioni",align: "end", value: "actions", sortable: false },
            ]
        }
    },
    mounted() {
        this.getOperators();
    },

    methods: {    
        async getOperators() {
            this.loading = true
            try {
                const response = await axios.get("/production_operators");
                this.operators = response.data.production_operators;
            }
            catch (err) {
                console.log(err);

            }
            finally {
                this.loading = false
            }
        }

    }
}

</script>